import { LayoutManager } from "./LayoutManager";
import playerLocation from "./players";
import gameState from "./gameState";
import constants from "./constants";

export class BidCallButtons {
  constructor(scene) {
    this.scene = scene;
    this.canvasWidth = scene.scale.width;
    this.canvasHeight = scene.scale.height;
    this.layoutManager = new LayoutManager(scene);
    this.bidButtonsGroup = this.scene.add.group();

    this.bidTillVinujdeniLabel = this.scene.add
      .text(
        this.canvasWidth / 2,
        this.layoutManager.getLayout().playAreaCenter.y + 60,
        "",
        {
          backgroundColor: constants.JOKER_CALL_BACKGROUND_COLOR,
          padding: {
            left: 10,
            right: 10,
            top: 5,
            bottom: 5,
          },
        }
      )
      .setOrigin(0.5, 0.5)
      .setFontSize(24)
      .setColor("#00ffff")
      .setVisible(false);
  }

  makeBidButtons() {
    // TODO Mobile
    // if (this.scene.scale.isLandscape) {
    //   return this.makeDesktopBidButtons();
    // } else {
    //   return this.makeMobileBidButtons();
    // }
    return this.makeDesktopBidButtons();
  }

  makeMobileBidButtons() {
    console.log("On makeMobileBidButtons");
    const bottomPlayerId = Object.keys(playerLocation).find(
      (key) => playerLocation[key].position === "bottom"
    );
    const buttonWidth = 60;
    const widerButtonWidth = 196;
    const buttonHeight = 44;
    const buttonSpacing = 8;
    const bidBoxHeight = buttonHeight * 4 + buttonSpacing * 3;
    const startX = this.layoutManager.getLayout().playAreaCenter.x;
    const startY =
      this.layoutManager.getLayout().playAreaCenter.y - bidBoxHeight / 2;

    if (bottomPlayerId === gameState.public.playerInTurnId) {
      let num = 0;
      for (let i = 0; i < 4; i++) {
        for (let j = 0; j < 3; j++) {
          if (i === 0) {
            j = 1;
          }
          // Add the bit buttons
          const button = this.scene.add
            .rectangle(
              startX + (buttonWidth + buttonSpacing) * (j - 1),
              startY + buttonHeight / 2 + (buttonHeight + buttonSpacing) * i,
              i > 0 ? buttonWidth : widerButtonWidth,
              buttonHeight,
              0x000000
            )
            .setStrokeStyle(2, 0xefc53f)
            .setOrigin(0.5, 0.5);

          // Display text on buttons and set them interactive until they are less then the numberOfCardsDealt
          if (num <= gameState.public.numberOfCardsDealt) {
            const text = this.scene.add
              .text(button.x, button.y, num === 0 ? "-" : num, {
                fontSize: 20,
                fill: "#fff",
                align: "center",
              })
              .setOrigin(0.5, 0.5);

            // Name the button. We will use this to read the button selection that player makes
            button.name = num;
            button.setInteractive();
            this.bidButtonsGroup.add(text);
          } else {
            button.setFillStyle(0x7b7d7d).disableInteractive();
          }
          this.bidButtonsGroup.add(button);

          // You can't call this bid because this means everyone might earn their bids.
          // bidNotAllowed is sent to the dealer from the server.
          if (num === gameState.public.bidNotAllowed) {
            button.setFillStyle(0x7b7d7d).disableInteractive();
          }
          if (
            // This is the third player making the bid:
            // bidNotAllowd value is only sent to the dealer from the server. Else it is null.
            // bidsTillVinujdeni value is only sent to the third player from the server, or else it is null.
            gameState.public.bidNotAllowed == null &&
            gameState.public.bidsTillVinujdeni != null &&
            gameState.public.bidsTillVinujdeni === button.name
          ) {
            if (gameState.public.bidsTillVinujdeni === 0) {
              gameState.public.bidsTillVinujdeni = "-";
            }
            button.setStrokeStyle(4, 0x00ff00);
            // item.setFillStyle(0x073b02);
            this.turnOnVinujdeniBidLabel(gameState.public.bidsTillVinujdeni);
          }

          ++num;
          if (i === 0) {
            break;
          }
        }
      }

      Phaser.Actions.Call(
        this.bidButtonsGroup.getChildren(),
        (item) => {
          item.on(
            "pointerdown",
            () => {
              console.log(`Selected bid: ${item.name}`);
              this.removeBidButtonsAndLabel();
              this.scene.socket.emit("bidMade", {
                playerId: this.scene.playerId,
                roomId: this.scene.roomId,
                bid: item.name,
              });
            },
            this
          );
        },
        this
      );
    }
    //this.scene.timer.startTimer(gameState.public.playerInTurnId);
  }

  makeDesktopBidButtons() {
    console.log("on makeDesktopBidButtons");
    const bottomPlayerId = Object.keys(playerLocation).find(
      (key) => playerLocation[key].position === "bottom"
    );
    if (bottomPlayerId === gameState.public.playerInTurnId) {
      let buttonsWidth = gameState.public.numberOfCardsDealt * 85;
      for (let i = 0; i <= gameState.public.numberOfCardsDealt; i++) {
        let num = i;
        const button = this.scene.add
          .circle(
            (this.canvasWidth - buttonsWidth) / 2 + i * 85,
            //this.canvasHeight / 2 + 120,
            this.layoutManager.getLayout().playAreaCenter.y + 160,
            constants.DESKTOP_BID_BUTTON_RADIUS,
            constants.colors["light"].bidCircle
          )
          .setStrokeStyle(4, constants.colors["light"].bidCircleStroke)
          .setInteractive();
        const text = this.scene.add
          .text(button.x, button.y, num === 0 ? "-" : num, {
            fontSize: 64,
            fill: "#fff",
          })
          .setOrigin(0.5, 0.5);

        button.name = num;
        //bidButton.setPadding(15).setStyle({ backgroundColor: '#FFFFFF', fontSize: 32 }).setInteractive();
        this.bidButtonsGroup.add(button);
        this.bidButtonsGroup.add(text);

        if (num === gameState.public.bidNotAllowed) {
          button.setFillStyle(0x7b7d7d).disableInteractive();
        }
        if (
          gameState.public.bidNotAllowed == null &&
          gameState.public.bidsTillVinujdeni != null &&
          gameState.public.bidsTillVinujdeni === button.name
        ) {
          if (gameState.public.bidsTillVinujdeni === 0) {
            gameState.public.bidsTillVinujdeni = "-";
          }
          //button.setStrokeStyle(4, 0x63090e);
          text.setColor("#00ffff");
          this.turnOnVinujdeniBidLabel(gameState.public.bidsTillVinujdeni);
        }
      }

      Phaser.Actions.Call(
        this.bidButtonsGroup.getChildren(),
        (item) => {
          item.on(
            "pointerdown",
            () => {
              console.log(`Selected bid: ${item.name}`);
              this.removeBidButtonsAndLabel();
              this.scene.socket.emit("bidMade", {
                playerId: this.scene.playerId,
                roomId: this.scene.roomId,
                bid: item.name,
              });
            },
            this
          );
        },
        this
      );
    }
    //this.scene.timer.startTimer(gameState.public.playerInTurnId);
  }

  turnOnVinujdeniBidLabel(vinujdeni) {
    this.bidTillVinujdeniLabel.text = `შევსებას უნდა ${vinujdeni}`;
    this.bidTillVinujdeniLabel.setVisible(true);
  }

  turnOffVinujdeniBidLabel() {
    this.bidTillVinujdeniLabel.text = "";
    this.bidTillVinujdeniLabel.setVisible(false);
  }

  removeBidButtonsAndLabel() {
    this.bidButtonsGroup.clear(true, true);
    this.turnOffVinujdeniBidLabel();
  }
}
