// import constants from "./constants";
// import playerLocation from "./players";

// export class Helper {
//   constructor(scene) {
//     this.scene = scene;
//     this.trumpCallCards = this.scene.add.group();
//     this.cardBacks = this.scene.add.group();
//   }

//   showTrumps() {
//     let joker = this.scene.add
//       .image(constants.width / 2 - 150, 425, "15_of_joker")
//       .setScale(0.25, 0.25)
//       .setOrigin(0)
//       .setInteractive();
//     let trump_clubs = this.scene.add
//       .image(constants.width / 2 - 90, 425, "15_of_clubs")
//       .setScale(0.25, 0.25)
//       .setOrigin(0)
//       .setInteractive();
//     let trump_diamonds = this.scene.add
//       .image(constants.width / 2 - 30, 425, "15_of_diamonds")
//       .setScale(0.25, 0.25)
//       .setOrigin(0)
//       .setInteractive();
//     let trump_hearts = this.scene.add
//       .image(constants.width / 2 + 30, 425, "15_of_hearts")
//       .setScale(0.25, 0.25)
//       .setOrigin(0)
//       .setInteractive();
//     let trump_spades = this.scene.add
//       .image(constants.width / 2 + 90, 425, "15_of_spades")
//       .setScale(0.25, 0.25)
//       .setOrigin(0)
//       .setInteractive();

//     this.trumpCallCards.add(joker);
//     this.trumpCallCards.add(trump_clubs);
//     this.trumpCallCards.add(trump_diamonds);
//     this.trumpCallCards.add(trump_hearts);
//     this.trumpCallCards.add(trump_spades);
//   }

//   hideTrumps() {
//     Phaser.Actions.Call(
//       this.trumpCallCards.getChildren(),
//       function (item) {
//         item.visible = false;
//       },
//       self
//     );
//   }

//   //   removeBidOptions() {
//   //     this.bidButtons.clear(true, true);
//   //   }

//   show3CardsBack(x, y) {
//     let firstCard = this.scene.add
//       .image(x, y, "card_back")
//       .setScale(0.2, 0.2)
//       .setOrigin(0.5);
//     let secondCard = this.scene.add
//       .image(x - 50, y, "card_back")
//       .setScale(0.2, 0.2)
//       .setOrigin(0.5);
//     let thirdCard = this.scene.add
//       .image(x + 50, y, "card_back")
//       .setScale(0.2, 0.2)
//       .setOrigin(0.5);

//     this.cardBacks.add(firstCard);
//     this.cardBacks.add(secondCard);
//     this.cardBacks.add(thirdCard);
//   }

//   hide3CardsBack() {
//     Phaser.Actions.Call(
//       this.cardBacks.getChildren(),
//       function (item) {
//         item.visible = false;
//       },
//       self
//     );
//   }

//   handler(x, y) {
//     console.log("handler");
//     this.add.image(x, y, "black_joker").setScale(0.25, 0.25);
//   }

//   setTrump(playerHand, playerInTurnId, dealerId) {
//     console.log("on setTrump");
//     // gameState.private.trumpCallCards = playerHand;
//     // gameState.public.playerInTurnId = playerInTurnId;
//     // gameState.public.dealerId = dealerId;
//     //Maybe take this outside of this mehtod
//     clearBidAndTrickBoxes();
//     const position = playerLocation[dealerId].position;
//     this.dealer.setDealerSign(position);
//     let trump = undefined;
//     if (playerHand[0] != undefined) {
//       let cardNum = 0;
//       playerHand.every((card) => {
//         ++cardNum;
//         let cardValue = card.rank + "_of_" + card.suit;
//         let cardsSpan = (playerHand.length + 1) * this.scene.cardWidth;
//         let cd = this.card.renderPlayingCard(
//           (constants.width - cardsSpan) / 2 + cardNum * this.scene.cardWidth,
//           constants.location.playerHand.y,
//           cardValue
//         );
//         trumpCallHand.add(cd);
//         return true;
//       });
//       this.helper.showTrumps();
//     } else {
//       console.log("Show 3 cards back");
//       this.show3CardsBack(
//         playerLocation[playerInTurnId].container.x,
//         playerLocation[playerInTurnId].container.y
//       );
//     }

//     startTimer(playerInTurnId);

//     // TODO take this outside
//     Phaser.Actions.Call(this.trumpCallCards.getChildren(), (item) => {
//       item.on("pointerdown", () => {
//         trump = item;
//         console.log(`Selected the trump: ${trump.texture.key}`);
//         trumpCallHand.clear(true, true);
//         this.socket.emit("announcedTrump", this.scene.roomId, trump);
//         this.hideTrumps();
//       });
//     });
//   }

//   //   playCardTwin(card, x, y) {
//   //     this.tweens.add({
//   //       targets: card,
//   //       x: { x, duration: 1500, ease: "Power2" },
//   //       y: { y, duration: 500, ease: "Bounce.easeOut", delay: 150 },
//   //     });
//   //   }

//   //   flip() {
//   //     this.add.sprite(600, 170, "flip").play("flip");
//   //   }
// }

export function getCard(textureKey) {
  let cardArray = textureKey.split("_of_");
  let rank = cardArray[0];
  let suit = cardArray[1];
  return { suit, rank };
}
