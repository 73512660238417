export default class Orientation {
  constructor(scene) {
    this.scene = scene;
  }

  checkOrientation(orientation) {
    console.log("Orientation on load: " + orientation);
    if (orientation === Phaser.Scale.PORTRAIT) {
      this.showRotateDeviceOverlay(this.scene);
    } else {
      this.hideRotateDeviceOverlay(this.scene);
    }
  }

  showRotateDeviceOverlay(scene) {
    if (!scene.rotateOverlay) {
      scene.rotateOverlay = scene.add
        .rectangle(
          0,
          0,
          scene.cameras.main.width,
          scene.cameras.main.height,
          0x000000,
          0.8
        )
        .setOrigin(0);
      scene.rotateText = scene.add
        .text(
          scene.cameras.main.width / 2,
          scene.cameras.main.height / 2,
          "Please rotate your device to landscape mode",
          {
            fontSize: "64px",
            color: "#ffffff",
            align: "center",
          }
        )
        .setOrigin(0.5);
    }
    scene.rotateOverlay.setDepth(1000);
    scene.rotateText.setDepth(1000);
    scene.rotateOverlay.setVisible(true);
    scene.rotateText.setVisible(true);
  }

  hideRotateDeviceOverlay(scene) {
    if (scene.rotateOverlay) {
      scene.rotateOverlay.setVisible(false);
      scene.rotateText.setVisible(false);
    }
  }
}
