import { io } from "socket.io-client";
import envConfig from "./envConfig.js";

let socket = null; // Socket instance

/**
 * Initializes and connects the socket.
 */
export function connectSocket() {
  if (socket && socket.connected) {
    console.log("✅ Socket already connected.");
    return;
  }

  console.log("Initializing socket...");

  // const userId = window.userId;
  // const email = window.email;
  // const username = window.username;

  const userId = sessionStorage.getItem("userId");
  const email = sessionStorage.getItem("email");
  const username = sessionStorage.getItem("username");

  if (!userId) {
    console.warn("⚠️ Cannot connect socket: User ID not found.");
    return;
  }

  const isCordova =
    window.location.protocol === "app:" || window.location.protocol === "file:";

  socket = io(envConfig.baseUrl, {
    transports: ["websocket", "polling"],
    withCredentials: true,
    auth: isCordova ? { userId, email, username } : {}, // 🔥 Ensure correct auth format
    reconnection: false, // 🔄 Enable auto-reconnect (if true, when server refreshes, cordova user automatically connects)
    // reconnectionAttempts: 5, // 🔄 Number of retry attempts
    // reconnectionDelay: 2000, // ⏳ Delay between attempts
  });

  // ✅ Connection established
  socket.on("connect", () => {
    console.log(`✅ Socket connected: ${socket.id}`);
  });

  // ⚠️ Handle disconnects
  socket.on("disconnect", (reason) => {
    console.warn(`⚠️ Socket disconnected: ${reason}`);
  });

  // ❌ Handle connection errors
  socket.on("connect_error", (error) => {
    console.error("❌ Socket connection error:", error.message || error);
  });
}

/**
 * Returns the active socket instance.
 */
export function getSocket() {
  if (!socket) {
    console.warn("⚠️ Socket instance requested before initialization.");
  }
  return socket;
}

/**
 * Disconnects the socket safely.
 */
export function disconnectSocket() {
  if (socket && socket.connected) {
    console.log("🔌 Disconnecting socket...");
    socket.disconnect();
  } else {
    console.warn("⚠️ No active socket connection to disconnect.");
  }
}
