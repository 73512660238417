import envConfig from "./envConfig.js";
import { startGame } from "./game.js";

const googleAuthUrl = `${envConfig.baseUrl}/auth/google`;
const facebookAuthUrl = `${envConfig.baseUrl}/auth/facebook`;

document.addEventListener("DOMContentLoaded", async () => {
  // document.body.style.visibility = "visible"; // Make visible after setup
  if (!isCordova()) {
    console.log("Checking authentication for web user...");

    try {
      const response = await fetch(`${envConfig.baseUrl}/api/user`, {
        credentials: "include", // Ensure cookies are sent
      });

      if (response.ok) {
        const user = await response.json();
        console.log("Authenticated User:", user);
        // window.userId = user.userId;
        // window.email = user.email;
        // window.username = user.username;
        // window.roomId = user.roomId;
        // Store user data in sessionStorage
        sessionStorage.setItem("userId", user.userId);
        sessionStorage.setItem("email", user.email);
        sessionStorage.setItem("username", user.username);
        sessionStorage.setItem("roomId", user.roomId);

        // connectSocket(); // Initialize socket after authentication

        // Dynamically import room module
        import("./room.js").then(() => console.log("Room module loaded"));
        if (user.roomId) {
          startGame();
        } else {
          showRoomSection();
        }
      } else {
        console.log("User not authenticated.");
        showLoginSection();
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
      showLoginSection();
    }
  }
});

document.addEventListener("deviceready", onDeviceReady, false);

function onDeviceReady() {
  console.log("Device is ready");
  window.handleOpenURL = handleCustomUrl;
  // ✅ Check if user is already authenticated from sessionStorage
  // const userId = window.userId;
  // const roomId = window.roomId;
  const userId = sessionStorage.getItem("userId");
  const roomId = sessionStorage.getItem("roomId");

  if (userId) {
    console.log("Cordova user already authenticated. Showing room section.");
    import("./room.js").then(() => console.log("Room module loaded"));
    if (roomId && roomId != "undefined") {
      startGame();
    } else {
      showRoomSection();
    }
  } else {
    console.log("Cordova user not authenticated. Showing login section.");
    showLoginSection();
  }
  setupLogin();
}

function handleCustomUrl(url) {
  console.log("Opened URL:", url);
  if (url.startsWith("myapp://callback")) {
    const userId = getParameterFromUrl(url, "userId");
    const email = getParameterFromUrl(url, "email");
    const username = getParameterFromUrl(url, "username");
    if (userId && email && username) {
      console.log("Authenticated User:", { userId, email, username });

      // window.userId = userId;
      // window.email = email;
      // window.username = username;

      // Store user info in sessionStorage
      sessionStorage.setItem("userId", userId);
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("username", username);

      SafariViewController.hide();
      // connectSocket();
      import("./room.js").then(() => console.log("Room module loaded"));
      showRoomSection();
    } else {
      console.error("Authentication failed: Missing user info.");
      showLoginSection();
    }
  }
}

function setupLogin() {
  console.log("Setting up login buttons");
  document
    .getElementById("google-login-button")
    .addEventListener("click", (event) =>
      handleAuthClick(event, googleAuthUrl)
    );

  document
    .getElementById("facebook-login-button")
    .addEventListener("click", (event) =>
      handleAuthClick(event, facebookAuthUrl)
    );
}

function handleAuthClick(event, fullUrl) {
  console.log("In handleAuthClick, " + fullUrl);
  if (event) {
    event.preventDefault();
    // event.stopPropagation();
  }

  const env = isCordova() ? "cordova" : "web";
  const oauthUrl = fullUrl + `?env=${env}`;

  if (env === "cordova") {
    SafariViewController.isAvailable((available) => {
      if (available) {
        SafariViewController.show(
          {
            url: oauthUrl,
            hidden: false,
            animated: false,
            toolbarColor: "#ffffff",
          },
          (result) => {
            if (result.event === "opened") {
              console.log("SafariViewController opened successfully.");
            } else if (result.event === "loaded") {
              console.log("SafariViewController loaded the URL.");
              //SafariViewController.hide();
            } else if (result.event === "closed") {
              console.log("SafariViewController closed.");
            }
          },
          (error) => {
            console.error("SafariViewController error:", error);
          }
        );
      } else {
        console.error("SafariViewController is not available.");
      }
    });
  } else {
    console.log("Browser environment, redirecting to:", fullUrl);
    window.location.href = oauthUrl; // Redirect in the browser
  }
}

function isCordova() {
  return (
    window.location.protocol === "app:" || window.location.protocol === "file:"
  );
}

function getParameterFromUrl(url, key) {
  return new URL(url).searchParams.get(key);
}

function showRoomSection() {
  console.log("Showing room section");
  document.querySelector(".header").style.display = "block";
  document.getElementById("loginSection").style.display = "none";
  document.getElementById("roomSection").style.display = "block";
}

function showLoginSection() {
  console.log("User not authenticated. Showing login section.");
  document.querySelector(".header").style.display = "block";
  document.getElementById("loginSection").style.display = "block";
  document.getElementById("roomSection").style.display = "none";
}
