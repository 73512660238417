import CardSprite from "../CardSprite.js";
import GameHandler from "../GameHandler.js";
import InteractivityHandler from "../InteractivityHandler.js";
import UIHandler from "../UIHandler.js";
import constants from "../constants.js";
import ProfileImage from "../ProfileImage.js";
import gameState from "../gameState.js";
import { BidAndTricks } from "../BidAndTricks.js";
import { Dealer } from "../Dealer.js";
import { JokerCall } from "../JokerCall.js";
import { Helper } from "../helpers.js";
import { Timer } from "../Timer.js";
import playerLocation from "../players.js";
import Orientation from "../orientation.js";
import { LayoutManager } from "../LayoutManager.js";
import { BidCallButtons } from "../BidCallButtons.js";
import { SpeechBubble } from "../SpeechBubble.js";
import ScoreSheetFull from "../ScoreSheetFull.js";
import Card from "../../../shared/Card.js";
import { getSocket } from "../socket.js";
import { JokerCallBanner } from "../JokerCallBanner.js";

// connectSocket();

export default class GameScene extends Phaser.Scene {
  constructor() {
    super({
      key: "GameScene",
    });
    this.roomId = null;
  }

  init(data) {
    this.playerId = data.playerId;
    this.roomId = data.roomId;
    console.log("User ID in init:", this.playerId);
    console.log("Room ID in init:", this.roomId);
  }

  preload() {}

  create() {
    this.socket = getSocket();
    let scene = this;

    // Set a new background color (for index.html)
    //document.documentElement.style.setProperty("--primary-bg", "#000000");

    // this.roomId = window.roomId;
    // this.playerId = window.userId;

    // TODO condider using "this" variables insead of const
    const roomId = this.roomId;
    const playerId = this.playerId;

    console.log(`Player ${playerId} from room ${roomId} is at the game table`);

    this.socket.emit("joinRoom", roomId);
    // const initialWidth = this.sys.game.canvas.width;
    // const initialHeight = this.sys.game.canvas.height;
    // const gameWidth = game.config.width;
    // const gameHeight = game.config.height;
    this.canvasWidth = scene.scale.width;
    this.canvasHeight = scene.scale.height;
    this.scaleFactor = this.canvasWidth / constants.REFERENCE_WIDTH;
    console.log("Screen width: " + this.canvasWidth);
    console.log("Screen height: " + this.canvasHeight);

    this.background = scene.add.tileSprite(
      0,
      0,
      this.sys.game.config.width,
      this.sys.game.config.height,
      "background"
    );

    // Set the origin to (0, 0) so the background starts at the top-left corner
    this.background.setOrigin(0, 0);
    this.card = new CardSprite(scene);
    this.uiHandler = new UIHandler(scene);
    this.jokerCall = new JokerCall(scene);
    this.bidAndTricks = new BidAndTricks(scene);
    //this.dealer = new Dealer(scene);
    this.timer = new Timer(scene, this.uiHandler);
    const orientation = new Orientation(scene);
    this.layoutManager = new LayoutManager(scene);
    const bidCallButtons = new BidCallButtons(scene);
    this.speechBubble = new SpeechBubble(scene);
    this.profileImage = new ProfileImage(scene);
    this.scoreSheet = new ScoreSheetFull(scene);

    const cardBack = new Card();
    cardBack.textureKey = "card_back";

    this.jokerCallBanner = new JokerCallBanner(scene, 0, 0, "");

    // Joker call bubble objects
    // this.speechBubble.createSpeechBubble(
    //   2.8,
    //   1.56,
    //   2,
    //   1,
    //   1,
    //   -1,
    //   6,
    //   2,
    //   "bottom"
    // );
    // this.speechBubble.createSpeechBubble(7, 3.5, 7, 1, 1, -1, 6, 2, "left");
    // //this.speechBubble.createSpeechBubble(2.8, 1.56, 2, 0, -1, -1, -1, 2, "top");
    // this.speechBubble.createSpeechBubble(2.8, 1.56, 2, 0, -1, 0, 0, 0, "top");
    // this.speechBubble.createSpeechBubble(
    //   1.4,
    //   1.17,
    //   1.17,
    //   1,
    //   1,
    //   2,
    //   6,
    //   2,
    //   "right"
    // );

    // this.speechBubble.createMessagePopup();

    // Check if the device is desktop
    this.isDesktop = this.sys.game.device.os.desktop;

    // Check if the device is in landscape mode
    this.isLandscape = this.scale.width > this.scale.height;

    // Log the results
    if (this.isDesktop) {
      console.log("This is a desktop device");
    } else {
      console.log("This is a mobile device");
    }

    if (this.isLandscape) {
      console.log("This is landscape mode");
    } else {
      console.log("This is portrait mode");
    }

    // Example: Adjust the game layout based on the orientation and device type
    if (this.isDesktop && this.isLandscape) {
      // Desktop landscape layout
    } else if (!this.isDesktop && this.isLandscape) {
      // Mobile landscape layout
    } else {
      // Portrait layout
    }

    // FIXME MOBILE - enable this.setPortraitLayout()
    if (screen.orientation.type === Phaser.Scale.PORTRAIT) {
      console.log("Portrait");
      // Adjust for portrait layout
      // this.setPortraitLayout();
      this.setLandscapeLayout();
    } else {
      console.log("Landscape");
      // Adjust for landscape layout
      this.setLandscapeLayout();
    }

    const roomKeyText = scene.add
      .text(this.canvasWidth - 230, 10, `Room ID: ${roomId}`, {
        fontSize: "24px",
        color: "#ffffff",
        backgroundColor: "#000000",
        padding: { x: 10, y: 5 },
      })
      .setInteractive();

    roomKeyText.on("pointerdown", () => {
      navigator.clipboard.writeText(roomId); // Copy room key to clipboard
      console.log("Room ID copied to clipboard");
    });

    // FIXME MOBILE - comment out
    // Needed if forcing only landcape
    orientation.checkOrientation(scene.scale.orientation);
    window.addEventListener("orientationchange", function () {
      // Obtain the new orientation from the window or screen object
      var newOrientation = screen.orientation.type || screen.orientation;
      // Call the checkOrientation function with the correct context and parameter

      // if methods are inside this class
      // orientation.checkOrientation.call(scene, newOrientation);
      orientation.checkOrientation(newOrientation);
    });

    // Create a button to show the score table
    // let showScoreTableButton = scene.add.text(100, 100, "Show Scores", {
    //   fontSize: "32px",
    //   fill: "#0f0",
    // });

    // window.addEventListener("resize", () => {
    //   game.scale.resize(window.innerWidth, window.innerHeight);
    // });

    //this.shuffle = this.add.text(850, 425, '').setOrigin(0.5, 0.5).setFontSize(18).setColor('#00ffff').setInteractive();
    //let actionsCircle;
    //this.bidButtons = this.add.group();

    // let requestHighestStand = this.make.graphics();
    // requestHighestStand.lineStyle(1, 0xff0000);
    // requestHighestStand.fillStyle(0x63090e, 0.5);
    // requestHighestStand.strokeRect(2, 0, 260, 115);
    // requestHighestStand.fillRect(2, 0, 258, 115);

    // let requestHighest = this.add
    //   .text(130, 100, "Request Highest")
    //   .setFont("16px Arial")
    //   .setColor("#ffffff")
    //   .setOrigin(0.5);

    // let renderTexture = this.add.renderTexture(0, 0, 264, 119);

    // renderTexture.draw(requestHighestStand);
    // renderTexture.draw(requestHighest);

    // renderTexture.saveTexture("newTexture");

    // requestHighestStand.destroy();
    // requestHighest.destroy();
    // renderTexture.destroy();

    this.lastTrick = [];
    this.droppedCards = [];
    this.lastTrickPlaceholder = scene.card
      .renderPlayingCard(
        this.layoutManager.getLayout().lastTrick.x,
        this.layoutManager.getLayout().lastTrick.y,
        cardBack
      )
      .setInteractive()
      .setVisible(false)
      .setOrigin(0);
    this.playingCards = this.add.group();
    let reactIconsGroup = this.add.group();
    let reactProfilesGroup = this.add.group();
    // this.spLight = this.add.pointlight(0, 0, 0, 120, 1);
    this.cardBacks = this.add.group();
    this.trumpCallCards = this.add.group();
    this.trumpCallHand = this.add.group();
    this.trumpCard;
    this.gameHandler = new GameHandler(scene);
    this.interactivityHandler = new InteractivityHandler(this);
    // this.cardPlayedSound = this.sound.add("card_played");
    this.playerHandObjects = [];
    let touchedProfile = undefined;

    this.logo = this.add
      .text(this.canvasWidth / 2, this.canvasHeight / 2, "JOKER", {
        color: "#000000",
        fontSize: 24,
        fontStyle: "bold",
      })
      .setOrigin(0.5)
      .setAlpha(0.1)
      .setVisible(false);

    function getOptimalCard(players) {
      /*
      if player has earned all the tricks, play the low card, else play high card.

      let bidMade = players[playerId].bidsMadeInCurrentRound;
      let tricksWon = players[playerId].tricksWonInCurrentRound;
          if(bidMade <= tricksWon){
            playLowCard
          }else{
            playHighCard
          }
      */
      // scene.GameHandler.legalCards[0];
    }

    let boo = this.add.text(0, 0, "👏").setName("boo").setOrigin(0, 0);
    let like = this.add.text(0, 0, "👍").setName("like").setOrigin(0, 0);
    let move = this.add.text(0, 0, "🐌").setName("move").setOrigin(0, 0);
    // let angry = this.add.text(0, 0, "😡").setName("angry").setOrigin(0, 0);
    let hissing = this.add.text(0, 0, "🤦‍♂️").setName("hissing").setOrigin(0, 0);
    reactIconsGroup.addMultiple([boo, like, move, hissing]).setVisible(false);

    Phaser.Actions.Call(reactProfilesGroup.getChildren(), function (item) {
      item.on("pointerover", showTaunts.bind(this, item));
      item.on("pointerout", noShowTaunts.bind(this, item));
    });

    Phaser.Actions.Call(
      reactIconsGroup.getChildren(),
      function (item) {
        console.log("On pointerdown");
        item.on("pointerdown", onLevelIconDown.bind(this, item));
      },
      this
    );

    // scene.showAllPulkaRows.on("pointerdown", (pointer) => {
    //   console.log("On toggle score table button down");
    //   showHidePulkas(null, false);
    // });

    this.socket.on("newPlayer", newPlayer);

    this.socket.on("existingPlayers", existingPlayers);

    this.socket.on("setupGame", setupGame);

    this.socket.on("setTrump", setTrump);

    // this.socket.on("waitingToSetTrump", waitingToSetTrump);

    this.socket.on("dealCards", dealCards);

    this.socket.on(
      "makeBid",
      bidCallButtons.makeBidButtons.bind(bidCallButtons)
    );

    this.socket.on("updateBidMade", updateBidMade);

    this.socket.on("cardPlayed", cardPlayed);

    this.socket.on("playCard", playCard);

    this.socket.on("flipTrickCards", flipTrickCards);

    this.socket.on("endTrick", endTrick);

    // this.socket.on("roundStart", roundStart);

    this.socket.on("endRound", endRound);

    this.socket.on(
      "jokerRequest",
      this.jokerCall.jokerRequest.bind(this.jokerCall)
    );

    this.socket.on("taunt", taunt);

    this.socket.on("gameOver", gameOver);

    this.socket.on("timeUp", timeUp);

    //add newest player (myself) to other sockets
    function newPlayer(player) {
      console.log("on newPlayer");
      addProfileImages(player);
    }

    this.socket.on("startTimer", (timerMillis) => {
      this.timer.startTimer(timerMillis);
    });

    // TODO emit event and disable interactive elements for player in turn
    function timeUp() {
      console.log("Timer Up...");
      scene.interactivityHandler.clearPopupSprites();
    }

    // Handle socket disconnection
    // this.socket.on("disconnect", () => {
    //   console.log("You have been disconnected from the game.");
    //   // Optionally redirect to a different page or show a message
    //   window.location.href = "/index.html";
    // });

    // TODO add gameState file??,
    // TODO we can just use gameState.players instead of setting them up here
    //FIXME Refresh doesn't work when player is about to announce trump
    function existingPlayers(gameState, myHand, trumpCallHand) {
      //TODO need to add render joker call (highest or suite takes)
      console.log("on existingPlayers");

      // Update gameState
      updatePublicGameState(gameState);
      console.log("gameState", gameState);

      // Set up players seats
      setupPlayerLocations();

      // Render cards that have been played in this trick
      renderCardsPlayed();

      // Update Score
      scene.scoreSheet.updateTable();

      // Render trump for current round
      renderTrump(gameState.trump);

      // scene.bidAndTricks.renderBidsAndTricksPlaceholders();
      scene.bidAndTricks.updateBidAmount(gameState.players);

      // Update bids placed, tricks won by, and premaia for players
      scene.bidAndTricks.updateWonTricks();

      // Render cover for the most recent trick in the current round
      renderLastTricksCover();

      // Render player images
      Object.keys(gameState.players).forEach((id) => {
        addProfileImages(gameState.players[id]);

        //setTimeout(() => {}, "4000");

        // The last won trick cover doesn't look pretty. Commenting
        // if (gameState.players[id].tricksWonInCurrentRound) {
        //   const position = playerLocation[id].position;
        //   scene.add
        //     .image(
        //       constants.location[position].trick.x,
        //       constants.location[position].trick.y,
        //       "card_back"
        //     )
        //     .setScale(0.16);
        // }
      });

      // Set dealer sign for a player
      scene.uiHandler.setDealerSign();
      scene.uiHandler.updatePremiaSign();

      if (trumpCallHand.length === 3) {
        setTrump(trumpCallHand, gameState.playerInTurnId, gameState.dealerId);
      } else {
        //scene.card.renderResponsivePlayerHand(myHand);

        scene.playerHandObjects = scene.card.createCardObjects(myHand);
        scene.playingCards.addMultiple(scene.playerHandObjects);
        scene.card.renderPlayerHand(scene.playerHandObjects);
        const bottomPlayerId = Object.keys(playerLocation).find(
          (key) => playerLocation[key].position === "bottom"
        );
        if (
          gameState.playerInTurnId === bottomPlayerId &&
          gameState.placedBids != undefined
        ) {
          bidCallButtons.makeBidButtons();
        } else {
          playCard(gameState.playerInTurnId);
        }
      }

      // Set up taunt icons around players
      if (Object.keys(gameState.players).length === 4) {
        Phaser.Actions.Call(reactProfilesGroup.getChildren(), function (item) {
          //scene.input.setHitAreaCircle(item, 60, 60, 80);
          scene.input.setHitAreaRectangle(item, 0, 0, 110, 30);
        });
      }
    }

    this.socket.on("updateGameState", (data) => {
      console.log("In updateGameState");
      if (data.public) {
        updatePublicGameState(data.public);
      }
      if (data.private) {
        updatePrivateGameState(data.private);
      }
      //updateGameWorld();
      // Example: Update visible game elements
    });

    function updatePublicGameState(publicState) {
      console.log("Updating public gameState", publicState);
      // Update game world based on public game state
      Object.assign(gameState.public, publicState);
      //updateGameWorld();
      // Example: Update visible game elements
    }

    function updatePrivateGameState(privateState) {
      console.log("Updating private gameState", privateState);
      // Update player-specific data
      Object.assign(gameState.private, privateState);
      //updateGameWorld();
      // Example: Update player-specific elements
    }

    function setupGame() {
      console.log("on setupGame");
      scene.scoreSheet.updateTable();
      //scene.scoreSheet.addNames(players);
      //scene.scoreSheet.updateTable();
      setupPlayerLocations();
      Phaser.Actions.Call(reactProfilesGroup.getChildren(), function (item) {
        //scene.input.setHitAreaCircle(item, 60, 60, 80);
        scene.input.setHitAreaRectangle(item, 0, 0, 110, 30);
        //item.setInteractive();
      });

      scene.socket.emit("dealMe", roomId);
    }

    function setTrump(playerHand, playerInTurnId, dealerId) {
      console.log("on setTrump");
      console.log(`PlayerInTurnId: ${playerInTurnId}. DealerId: ${dealerId}`);
      // gameState.private.trumpCallCards = playerHand;
      // gameState.public.playerInTurnId = playerInTurnId;
      // gameState.public.dealerId = dealerId;
      //FIXME take this outside of this mehtod
      scene.bidAndTricks.clearBidAndTrickBoxes();
      // scene.bidAndTricks.clearPremiaPlaceholders();
      scene.uiHandler.setDealerSign();
      scene.uiHandler.updatePremiaSign();
      //let trump = undefined;
      if (playerHand[0] != undefined) {
        let trumps = scene.card.createCardObjects(playerHand);
        scene.trumpCallHand.addMultiple(trumps);
        scene.card.renderPlayerHand(trumps);
        scene.showTrumps();
      } else {
        console.log("Show 3 cards back");
        // TODO Works but not pretty
        // scene.show3CardsBack(
        //   playerLocation[playerInTurnId].container.x,
        //   playerLocation[playerInTurnId].container.y
        // );
      }

      //scene.timer.startTimer(playerInTurnId);

      // TODO take this outside
      Phaser.Actions.Call(
        scene.trumpCallCards.getChildren(),
        function (item) {
          item.on(
            "pointerdown",
            () => {
              console.log(`Selected trump: `, item.cardData);
              scene.clearTrumpCallSprites();
              scene.socket.emit(
                "announcedTrump",
                playerId,
                roomId,
                item.cardData
              );
            },
            scene
          );
        },
        scene
      );
    }

    function dealCards(playerHand, trump, dealerId) {
      console.log("on dealCards");
      console.log("playerHand: ", playerHand);

      // This is needed for when server announces the trump
      scene.clearTrumpCallSprites();
      gameState.public.dealerId = dealerId;

      // if new pulka starts, we need to clear premia indicator
      const roundNumber = gameState.public.gameRound;

      if (
        gameState.public.inPulkaRound === 0
        // roundNumber === 9 ||
        // roundNumber === 13 ||
        // roundNumber === 21 ||
        // roundNumber === 25
      ) {
        // scene.bidAndTricks.clearPremiaPlaceholders();
      }
      // clear data from the previous round; can be moved into 'endedDarigeba' event
      //scene.trumpCard?.destroy();
      //scene.dealerContainer.visible = false;
      // scene.bidAndTricks.clearBidAndTrickBoxes();

      // render initial data from current round
      //Works but not pretty
      //TODO scene.hide3CardsBack();

      scene.uiHandler.setDealerSign();
      scene.uiHandler.updatePremiaSign();

      //scene.card.renderResponsivePlayerHand(playerHand);

      scene.playerHandObjects = scene.card.createCardObjects(playerHand);
      scene.playingCards.addMultiple(scene.playerHandObjects);
      scene.card.renderPlayerHand(scene.playerHandObjects);

      // scene.bidAndTricks.renderBidsAndTricksPlaceholders(playerHand.length);
      renderTrump(trump);
      scene.socket.emit("ready", roomId);
    }

    function flipTrickCards(playerId) {
      console.log("on flipTrickCards");
      scene.lastTrick = [...scene.droppedCards];
      while (scene.droppedCards.length) {
        scene.droppedCards.pop();
      }

      // Trick/s taken to be updated immediately after last card is played, so that the players have enough time (about 3 sec.) to observe the results.
      scene.bidAndTricks.updateWonTricks();
      scene.uiHandler.updatePremiaSign();

      // Wait for one second for the dropped cards to stay in focus before flipVziatka tween starts.
      setTimeout(() => {
        flipVziatka(playerId);
        console.log("Delayed for 1 second.");
      }, "1000");
      // scene.GameHandler.playerInTurnId = undefined;
      gameState.public.playerInTurnId = undefined;
    }

    function endTrick() {
      console.log("Trick ended");
      // Scenario: 4th card has been played and flipVziatka event has been fired.
      // but the browser is slow and flipVziatka tween is not starting yet.
      // Meanwhile endTrick and endDarigaba events have been fired and players alreay have their new cards.
      // Only at this point the flipVziatka tweens start - we want to avoid this, so when endTrick event is fired,
      // we are clearing cards so that the flipVziaka event has no cards to flip

      clearFlipVziatkaCards();

      // If sprites are activated by a player, destroy them
      scene.interactivityHandler.clearPopupSprites();

      // Show last trick cover card back
      scene.lastTrickPlaceholder.setVisible(true);
    }

    // TODO all this can be moved into 'dealCards' event and this event deleted. Or some other methods be moved here from the 'dealCards' event.
    // However, this way, there is a second gap between clearing old data and displaying new one
    function endRound() {
      console.log("Round ended");

      // Destroy trump card
      scene.trumpCard?.destroy();

      // Clear bids and tricks
      scene.bidAndTricks.clearBidAndTrickBoxes();

      // Remove last trick cover
      scene.lastTrickPlaceholder.setVisible(false);

      // Remove Dealer sign
      for (let cont of scene.uiHandler.containerArray) {
        const dealer = scene.uiHandler.findItemByName(cont, "dealer");
        //const dealer = cont.list.find((item) => item.name === "dealer");
        dealer.visible = false;
      }

      // Update score table
      scene.scoreSheet.updateTable();
    }

    function clearFlipVziatkaCards() {
      // Destroys sprites
      for (let i = 0; i < scene.lastTrick.length; i++) {
        scene.lastTrick[i].destroy();
      }
      // Empties the array []
      while (scene.lastTrick.length) {
        scene.lastTrick.pop();
      }
    }

    function flipVziatka(playerId) {
      const position = playerLocation[playerId].position;
      scene.lastTrickPlaceholder.depth = 100;
      let xLoc = scene.layoutManager.getLayout()[position].x;
      let yLoc = scene.layoutManager.getLayout()[position].y;

      // Hide joker call text
      //scene.speechBubble.hideMessage();
      scene.jokerCallBanner.setVisible(false);

      const timeLine = scene.tweens.timeline({
        onComplete: () => {
          console.log("Completed vziatka flip");
          console.log(scene.lastTrick);

          clearFlipVziatkaCards();
          //scene.add.image(xLoc, yLoc, "card_back").setScale(0.16);
        },
      });

      timeLine.add({
        delay: 200,
        targets: scene.lastTrick,
        duration: 300,
        //scale: 0.1,
        x: scene.layoutManager.getLayout().playAreaCenter.x,
        y: scene.layoutManager.getLayout().playAreaCenter.y,
      });

      timeLine.add({
        delay: 100,
        targets: scene.lastTrick,
        scale: 0.1,
        x: xLoc,
        y: yLoc,
        duration: 300,
      });

      timeLine.play();
    }

    function taunt(name, actionReceiverId) {
      console.log("on Taunt");
      var music = scene.sound.add(name);

      // if (dur < music.duration) {
      //   dur = music.duration;
      //   console.log("new duration: " + dur)
      // }

      music.on("play", () => {
        this.playerLocation[actionReceiverId].container.list[0].setStrokeStyle(
          5,
          0xefc53f
        );

        // scene.tweens.add({
        //   targets: playerLocation[actionReceiverId].container.list[0],
        //   alpha: 0,
        //   ease: 'Cubic.easeOut',
        //   duration: 50,
        //   //totalDuration: dur,
        //   repeat: dur / 0.250,
        //   yoyo: true,
        // })
        console.log(music.isPlaying);
      });

      music.on("complete", () => {
        this.playerLocation[actionReceiverId].container.list[0];
        //music.stop();
        this.playerLocation[
          actionReceiverId
        ].container.list[0].setStrokeStyle();
        //console.log(music)
      });

      music.play();

      // if (!music.isPlaying) {
      //   console.log("NOT PLAYING")
      //   this.playerLocation[actionReceiverId].container.list[0].setStrokeStyle();
      // }
      // scene.sound.add(name).play({
      //   seek: 0
      // });
      //console.log(actionReceiverId);
      // let p = Object.keys(this.playerLocation).find(key => this.playerLocation[key].container.list[0] === actionReceiverId);
      //console.log(p)
      //this.playerLocation[actionReceiverId].container.list[0].setFillStyle('0x00ff00')
      //console.log(name)
    }

    function addProfileImages(player) {
      console.log("Adding player image and name:", player);
      let xLoc = 0;
      let yLoc = 0;
      let cont = undefined;
      let imageWidth = constants.DESKTOP_IMAGE_WIDTH;
      let imageHeight = constants.DESKTOP_IMAGE_HEIGHT;

      const bottomPlayerId = Object.keys(playerLocation).find(
        (key) => playerLocation[key].position === "bottom"
      );

      if (player.playerPositions.leftPlayerId === bottomPlayerId) {
        xLoc = scene.layoutManager.getLayout().right.x;
        yLoc = scene.layoutManager.getLayout().right.y;
        cont = scene.uiHandler.rightContainer;
        console.log("adding right player");
      } else if (player.playerPositions.topPlayerId === bottomPlayerId) {
        xLoc = scene.layoutManager.getLayout().top.x;
        yLoc = scene.layoutManager.getLayout().top.y;
        cont = scene.uiHandler.topContainer;
        console.log("adding top player");
      } else if (player.playerPositions.rightPlayerId === bottomPlayerId) {
        xLoc = scene.layoutManager.getLayout().left.x;
        yLoc = scene.layoutManager.getLayout().left.y;
        cont = scene.uiHandler.leftContainer;
        console.log("adding left player");
      } else {
        xLoc = scene.layoutManager.getLayout().bottom.x;
        yLoc = scene.layoutManager.getLayout().bottom.y;
        cont = scene.uiHandler.bottomContainer;
        console.log("adding bottom player");
      }

      // Check if the texture is already loaded
      // const profileKey = `playerProfile_${player.socketId}`; // Unique key for each player
      // if (!scene.textures.exists(profileKey)) {
      //   scene.load.image(profileKey, player.profilePic);
      //   scene.load.once("complete", () => {
      //     addProfileImage(scene, xLoc, yLoc, profileKey);
      //   });
      //   scene.load.start();
      // } else {
      //   addProfileImage(scene, xLoc, yLoc, profileKey);
      // }

      scene.load.on("loaderror", (file) => {
        console.error(`Failed to load image: ${file.key}`);
        // const avatar = scene.add
        //   .image(xLoc, yLoc, "round-avatar")
        //   .setScale(0.3);
      });

      // Display player name
      const playerName = scene.uiHandler.findItemByName(cont, "name");
      // playerName.setText(player.name).setVisible(true);
      scene.uiHandler.setName(playerName, player.name);

      // image.displayWidth = 50;
      // image.displayHeight = 25;
      // TODO Mobile
      if (!scene.isLandscape) {
        cont.setText(player.name);
      } else {
        scene.profileImage.addProfileImage(
          xLoc,
          yLoc,
          imageWidth,
          imageHeight,
          player.profilePic,
          1
        );
      }

      console.log("added the players image and name to scene");
    }

    function addProfileImage(scene, x, y, key) {
      const image = scene.add.image(x, y, key).setOrigin(0.5);
      image.displayWidth = constants.DESKTOP_IMAGE_WIDTH;
      image.displayHeight = constants.DESKTOP_IMAGE_HEIGHT;

      const maskShape = scene.make.graphics({ x: 0, y: 0, add: false });
      maskShape.fillStyle(0xffffff);
      maskShape.fillCircle(x, y, constants.DESKTOP_IMAGE_WIDTH / 2); // Radius is half of display width/height for a perfect fit

      // Create the mask and apply it to the image
      const mask = maskShape.createGeometryMask();
      image.setMask(mask);
    }

    // function waitingToSetTrump(playerInTurnId, dealerId) {
    //   console.log("on waitingToSetTrump");
    //   clearBidAndTrickBoxes();
    //   scene.setDealerSign(dealerId);
    //   startTimer(playerInTurnId);
    //   if (scene.GameHandler.selfPlayer != playerInTurnId) {
    //     scene.show3CardsBack(
    //       playerLocation[playerInTurnId].container.x,
    //       playerLocation[playerInTurnId].container.y
    //     );
    //   }
    // }

    function cardPlayed(cardData, playerId, jokerDemand) {
      console.log(`Player ${playerId} played card:`, cardData);
      // scene.cardPlayedSound.play();

      // Destroy ongoing timebar for everyone so new one can start
      scene.timer.destroyTimeBar();

      // Postion of the player who played the recent card relative to the bottom player.
      const position = playerLocation[playerId].position;

      let playedCard = null;

      if (position === "bottom") {
        // Incoming card is a card object and not phaser's gameObject. We need to find the corresponding card in players hand.
        // If the player is who played the card, we should move the card from player's hand to the table, and then remove it from the his hand
        playedCard = scene.playerHandObjects.find(
          (c) => c.cardData.textureKey == cardData.textureKey
        );
        console.log(playedCard);
        playCardForBottomPlayer(playedCard);
        //scene.interactivityHandler.clearPopupSprites();
      } else {
        // If the player is not the player who played the recent card, we should render the card at the table because the card does not exist for these players.
        playedCard = scene.card
          .renderPlayingCard(0, 0, cardData)
          .disableInteractive()
          .setTint()
          .setOrigin(0.5);

        // push the card into an array. We will use this array first to tween them and then to delete at once.
        // Also, having this variable here is important because getPlayedCardLocation() method uses these cards to find the height of cards
        scene.droppedCards.push(playedCard);

        // Set the location of the card where the player who played it sits
        const { xLoc, yLoc } = scene.getPlayedCardLocation(playerId);
        playedCard.setX(xLoc);
        playedCard.setY(yLoc);
      }

      if (cardData.rank == 5) {
        // Shows joker in a faded state to distinguish the demand from call.
        playedCard.setAlpha(0.3);
      } else {
        // This does not do anything if cards to not overlap
        scene.children.bringToTop(playedCard);
      }

      // Display speech bubble whe joker annouces demand/call
      if (jokerDemand) {
        scene.jokerCall.jokerRequest(cardData, playerId);
      }

      // after a card has been played, disable all cards in all the players hands
      for (let i in scene.playerHandObjects) {
        scene.playerHandObjects[i]
          .disableInteractive()
          .setTint(constants.colors["light"].disabledCard);
      }
    }

    // This method moves a card from player's hand onto the table. We should be careful not to render another card in this case
    function playCardForBottomPlayer(card) {
      // push the card into an array. We will use this array first to tween them and then to delete at once.
      // Also, having this variable here is important because getPlayedCardLocation() method uses these cards to find the height of cards
      scene.droppedCards.push(card);

      // Tween effect of playing card from players hand onto the table
      card.setOrigin(0.5);
      scene.tweens.add({
        targets: card,
        x: {
          value: scene.layoutManager.getLayout().playAreaCenter.x,
          duration: 100,
        },
        y: {
          value:
            scene.layoutManager.getLayout().playAreaCenter.y +
            (card.displayHeight + scene.card.padding) / 2,
          duration: 100,
        },
      });

      let removed = Phaser.Utils.Array.Remove(scene.playerHandObjects, card);
      console.log(`Remove card from player's hand:`, removed);

      // re-render player hand to remove the gap where the card was removed from
      scene.card.renderPlayerHand(scene.playerHandObjects);
    }

    // TODO Is there an issue with sending the 'players' Object or do we need to send the "score" Object instead?
    function updateBidMade(players) {
      console.log("on updateBidMade");
      scene.timer.destroyTimeBar();
      bidCallButtons.removeBidButtonsAndLabel();
      scene.bidAndTricks.updateBidAmount(players);
      scene.scoreSheet.updateTable();
    }

    function playCard(playerInTurnId) {
      console.log("on change Turn");
      console.log("playerInTurnId");
      console.log(playerInTurnId);
      //destroyTimeBar()?
      // scene.GameHandler.playerInTurnId = playerInTurnId;
      gameState.public.playerInTurnId = playerInTurnId;
      scene.gameHandler.setLegalCards();

      // if (playerInTurnId != undefined) {
      //   scene.timer.startTimer(playerInTurnId);
      // }
    }

    function onLevelIconDown(sprite, pointer, x, y, PropagationObj) {
      let touchedProfileId = Object.keys(playerLocation).find(
        (key) => playerLocation[key].container.name === touchedProfile.name
      );
      scene.socket.emit("taunt", roomId, sprite.name, touchedProfileId);
    }

    function showTaunts(sprite, pointer, x, y, PropagationObj) {
      touchedProfile = sprite.parentContainer;
      scene.input.topOnly = false;
      console.log(sprite.parentContainer);
      actionsCircle = new Phaser.Geom.Circle(
        sprite.parentContainer.x + 60,
        sprite.parentContainer.y,
        70
      );
      Phaser.Actions.PlaceOnCircle(
        reactIconsGroup.getChildren(),
        actionsCircle,
        -1.4,
        2
      );
      actionsCircle = new Phaser.Geom.Line(
        sprite.parentContainer.x - 60,
        sprite.parentContainer.y - 100,
        sprite.parentContainer.x + 60,
        sprite.parentContainer.y - 100
      );
      Phaser.Actions.PlaceOnLine(reactIconsGroup.getChildren(), actionsCircle);
      Phaser.Actions.Call(
        reactIconsGroup.getChildren(),
        function (item) {
          item.setVisible(true);
          item.setInteractive();
        },
        this
      );
    }

    function noShowTaunts(sprite, pointer, x, y, PropagationObj) {
      console.log("No Show Taunts");
      Phaser.Actions.Call(reactIconsGroup.getChildren(), function (item) {
        item.setVisible(false);
        //item.disableInteractive();
      });
      scene.input.topOnly = true;
    }

    function renderTrump(trump) {
      if (trump != undefined || trump != null) {
        scene.trumpCard = scene.card
          .renderPlayingCard(
            scene.layoutManager.getLayout().trump.x,
            scene.layoutManager.getLayout().trump.y,
            trump
          )
          .setOrigin(0);
        console.log("Trump card: " + scene.trumpCard);
        //scene.trumpCard.rotation = 0.75;
        //scene.trumpCard.angle = -45;
      }
    }

    function setupPlayerLocations() {
      const players = gameState.public.players;
      console.log(players);
      let playerId = Object.keys(players).find(
        (id) => players[id].socketId === scene.socket.id
      );

      playerLocation[playerId] = {
        container: scene.uiHandler.bottomContainer,
        position: "bottom",
      };
      playerLocation[players[playerId].playerPositions.leftPlayerId] = {
        container: scene.uiHandler.leftContainer,
        position: "left",
      };
      playerLocation[players[playerId].playerPositions.topPlayerId] = {
        container: scene.uiHandler.topContainer,
        position: "top",
      };
      playerLocation[players[playerId].playerPositions.rightPlayerId] = {
        container: scene.uiHandler.rightContainer,
        position: "right",
      };
    }

    function renderCardsPlayed() {
      const cardsPlayed = gameState.public.cardsPlayed;
      cardsPlayed.forEach(({ playerId, card }, index) => {
        let cardSprite = scene.card
          .renderPlayingCard(0, 0, card)
          .disableInteractive()
          .setTint()
          .setOrigin(0.5);
        scene.droppedCards.push(cardSprite);
        const { xLoc, yLoc } = scene.getPlayedCardLocation(playerId);
        cardSprite.setX(xLoc);
        cardSprite.setY(yLoc);
        if (index === 0 && card.textureKey.includes("joker")) {
          scene.jokerCall.jokerRequest(card, playerId);
        }
      });
      // if (cardsPlayed[0]?.card.textureKey.includes("joker")) {
      //   scene.jokerCall.jokerRequest(
      //     cardsPlayed[0].card,
      //     cardsPlayed[0].playerId
      //   );
      // }
    }

    function renderLastTricksCover() {
      console.log("In renderLastTricksCover");
      scene.lastTrickPlaceholder.setVisible(
        gameState.public.lastWonTrick.length > 0 || false
      );
    }

    function gameOver(players) {
      console.log("Game finished - starting Game Over scene");
      scene.scene.stop();
      scene.scene.start("GameOver", {
        players: players,
        socket: scene.socket,
      });
    }

    window.scene = this;
  }

  update() {}

  getPlayedCardLocation(playerId) {
    let xLoc = 0;
    let yLoc = 0;
    const firstPlayedCard = this.droppedCards[0];
    console.log(firstPlayedCard);
    const position = playerLocation[playerId].position;
    //const cardDisplayWidth = playedCard.displayWidth;
    const cardDisplayWidth = scene.card.cardDisplayWidth;
    const cardDisplayHeight = firstPlayedCard.displayHeight;
    if (position === "bottom") {
      xLoc = scene.layoutManager.getLayout().playAreaCenter.x;
      yLoc =
        scene.layoutManager.getLayout().playAreaCenter.y +
        (cardDisplayHeight + scene.card.padding) / 2;
    } else if (position === "left") {
      xLoc =
        scene.layoutManager.getLayout().playAreaCenter.x -
        (cardDisplayWidth + scene.card.padding);
      yLoc = scene.layoutManager.getLayout().playAreaCenter.y;
    } else if (position === "top") {
      xLoc = scene.layoutManager.getLayout().playAreaCenter.x;
      yLoc =
        scene.layoutManager.getLayout().playAreaCenter.y -
        (cardDisplayHeight + scene.card.padding) / 2;
    } else if (position === "right") {
      xLoc =
        scene.layoutManager.getLayout().playAreaCenter.x +
        (cardDisplayWidth + scene.card.padding);
      yLoc = scene.layoutManager.getLayout().playAreaCenter.y;
    }
    return { xLoc, yLoc };
  }

  showTrumps() {
    const noTrump = new Card("joker", "15", 15, "15_of_joker");
    const hearts = new Card("hearts", "15", 15, "15_of_hearts");
    const spades = new Card("spades", "15", 15, "15_of_spades");
    const diamonds = new Card("diamonds", "15", 15, "15_of_diamonds");
    const clubs = new Card("clubs", "15", 15, "15_of_clubs");

    const cardDataArray = [noTrump, hearts, spades, diamonds, clubs];
    const suiteCards = this.card.createCardObjectsFromName(cardDataArray);
    suiteCards.forEach((card) => {
      card.setOrigin(0.5).setInteractive().setTint();
    });
    this.trumpCallCards.addMultiple(suiteCards);
    this.card.renderPlayerHand(
      suiteCards,
      this.layoutManager.getLayout().playAreaCenter.y
    );
  }

  hideTrumps() {
    Phaser.Actions.Call(
      this.trumpCallCards.getChildren(),
      function (item) {
        item.visible = false;
      },
      scene
    );
  }

  clearTrumpCallSprites() {
    this.hideTrumps();
    this.trumpCallHand.clear(true, true);
  }

  // removeBidOptions() {
  //   this.bidButtons.clear(true, true);
  // }

  show3CardsBack(x, y) {
    let firstCard = this.add
      .image(x, y, "card_back")
      .setScale(0.2, 0.2)
      .setOrigin(0.5);
    let secondCard = this.add
      .image(x - 50, y, "card_back")
      .setScale(0.2, 0.2)
      .setOrigin(0.5);
    let thirdCard = this.add
      .image(x + 50, y, "card_back")
      .setScale(0.2, 0.2)
      .setOrigin(0.5);

    this.cardBacks.add(firstCard);
    this.cardBacks.add(secondCard);
    this.cardBacks.add(thirdCard);
  }

  hide3CardsBack() {
    Phaser.Actions.Call(
      this.cardBacks.getChildren(),
      function (item) {
        item.visible = false;
      },
      scene
    );
  }

  setPortraitLayout() {
    // Adjust the positions and size of game objects for portrait mode
    console.log("Portrait layout applied");
    // document.querySelector(".score-sheet").style.width =
    //   this.canvasWidth * 0.7 + "px";
    // Example: Adjust card positions for portrait
    // document.querySelector(".score-sheet").style.width = "390px";
    this.uiHandler.buildMobileUI();
    //this.dealer.builMobiledDearSign();
    //showOnlyActiveDarigeba();
  }

  setLandscapeLayout() {
    // Adjust the positions and size of game objects for landscape mode
    console.log("Landscape layout applied");
    // document.querySelector(".score-sheet").style.width =
    //   this.canvasWidth * 0.2 + "px";
    // document.querySelector(".score-sheet").style.height =
    //   this.canvasHeight + "px";
    //document.querySelector(".score-sheet").style.height = this.canvasHeight;
    // Example: Adjust card positions for landscape
    this.uiHandler.builDesktopUI();
    //this.dealer.buildDesktopDearSign();
  }

  // handler(x, y) {
  //   console.log("handler");
  //   this.add.image(x, y, "black_joker").setScale(0.25, 0.25);
  // }

  // playCardTwin(card, x, y) {
  //   this.tweens.add({
  //     targets: card,
  //     x: { x, duration: 1500, ease: "Power2" },
  //     y: { y, duration: 500, ease: "Bounce.easeOut", delay: 150 },
  //   });
  // }

  // spotlight(x, y) {
  //   let colorIndex = 0;

  //   const spectrum = Phaser.Display.Color.ColorSpectrum(128);

  //   let color = spectrum[colorIndex];

  //   this.spLight.color.setTo(219, 215, 101);
  //   //colorIndex++;

  //   this.spLight.x = x;
  //   this.spLight.y = y;
  // }
}
