import { LayoutManager } from "./LayoutManager";

export class SpeechBubble {
  constructor(scene) {
    this.scene = scene;
    this.layoutManager = new LayoutManager(scene);
    this.bubbleWidth = 120;
    this.bubbleHeight = 30;
    this.bubbleXloc = this.layoutManager.getLayout()["top"].x;
    this.bubbleYloc = this.layoutManager.getLayout().speechBubble.y + 10;
    this.speechBubbleArray = [];
    this.messageText = "";
  }

  createSpeechBubble(x1, x2, x3, y1, y3, l1, l2, l3, name) {
    const _bubbleWidth = this.bubbleWidth;
    const _bubbleHeight = this.bubbleHeight;
    const x = this.bubbleXloc - _bubbleWidth / 2;
    const y = this.bubbleYloc - _bubbleHeight / 2;
    const arrowHeight = _bubbleHeight / 4;
    const bubble = this.scene.add.graphics({ x: x, y: y }).setDepth(10);

    //  Bubble shadow
    bubble.fillStyle(0x222222, 0.5);
    bubble.fillRoundedRect(6, 6, _bubbleWidth, _bubbleHeight, 6);

    //  Bubble color
    bubble.fillStyle(0xffffff, 1);

    //  Bubble outline line style
    bubble.lineStyle(4, 0x565656, 1);

    //  Bubble shape and outline
    bubble.strokeRoundedRect(0, 0, _bubbleWidth, _bubbleHeight, 6);
    bubble.fillRoundedRect(0, 0, _bubbleWidth, _bubbleHeight, 6);

    //  Calculate arrow coordinates
    const point1X = Math.floor(_bubbleWidth / x1);
    const point1Y = _bubbleHeight * y1;
    const point2X = Math.floor(_bubbleWidth / x2);
    const point2Y = _bubbleHeight * y1;
    const point3X = Math.floor(_bubbleWidth / x3);
    const point3Y = Math.floor(_bubbleHeight * y1 + arrowHeight * y3);

    //  Bubble arrow shadow
    bubble.lineStyle(4, 0x222222, 0.5);
    bubble.lineBetween(point2X + l1, point2Y + l2, point3X + l3, point3Y);

    //  Bubble arrow fill
    bubble.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y);
    bubble.lineStyle(2, 0x565656, 1);
    bubble.lineBetween(point2X, point2Y, point3X, point3Y);
    bubble.lineBetween(point1X, point1Y, point3X, point3Y);
    bubble.setName(name).setVisible(false);
    this.speechBubbleArray.push(bubble);
  }

  // Function to create the message popup
  createMessagePopup() {
    const bubblePadding = 2;

    // Create a container to hold the popup
    const popupContainer = this.scene.add
      .container(this.bubbleXloc, this.bubbleYloc)
      .setDepth(10);

    // Create the text for the popup (empty initially)
    this.messageText = this.scene.add
      .text(0, 0, "", {
        fontFamily: "Arial",
        fontSize: 20,
        color: "#000000",
        align: "center",
        wordWrap: { width: this.bubbleWidth - bubblePadding * 2 },
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);

    popupContainer.add(this.messageText);
  }

  // Function to show the popup with new content
  showMessage(content) {
    console.log(content);
    this.messageText.setText(content);
    this.messageText.setVisible(true); // Show the popup

    // Optionally, you can hide the popup after a delay
    //this.time.delayedCall(3000, () => this.hideMessage());
  }

  // Function to hide the popup
  hideMessage() {
    console.log("Hide message");
    this.messageText.setVisible(false);
    this.speechBubbleArray.forEach((bubble) => {
      bubble.setVisible(false);
    });
  }
}
