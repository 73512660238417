import gameState from "./gameState";

export default class ScoreSheetFull {
  constructor(scene) {
    this.scene = scene;
    this.canvasHeight = scene.scale.height;
    this.sections = []; // To keep track of sections
    const columns = 8;
    const rows = 30;
    this.dealColWidth = 25;
    this.bidCellWidth = 22;
    this.cellWidth = 80;
    this.cellHeight = this.canvasHeight / rows; //33;
    this.bidPadding = 5;
    const tableWidth = this.cellWidth * 4 + this.dealColWidth;
    const tableHeight = rows * this.cellHeight;
    this.bidsTextArray = [];
    this.pointsTextArray = [];

    // Draw table background (nice grey: 0x2e2e2e)
    this.scene.add
      .graphics()
      .fillStyle(0x1a374d)
      .fillRect(0, 0, tableWidth, tableHeight);

    // Define line style for table grid
    const graphics = this.scene.add.graphics({
      lineStyle: { width: 1, color: 0xffffff },
    });

    // Color pulka scores
    for (let i = 0; i < 4; i++) {
      this.fillCell(8, i, 0xf5f5dc);
    }

    for (let i = 0; i < 4; i++) {
      this.fillCell(13, i, 0xf5f5dc);
    }

    for (let i = 0; i < 4; i++) {
      this.fillCell(22, i, 0xf5f5dc);
    }

    for (let i = 0; i < 4; i++) {
      this.fillCell(27, i, 0xf5f5dc);
    }

    // Draw rows and create sections
    for (let row = 0; row <= rows; row++) {
      graphics.lineBetween(
        0,
        row * this.cellHeight,
        tableWidth,
        row * this.cellHeight
      );

      // Example: Create sections for rows 0-9, 10-19, 20-29
      // if (row % 10 === 0 && row < 30) {
      //   const sectionHeight = Math.min(10, rows - row) * cellHeight;
      //   const section = this.add
      //     .graphics()
      //     .fillStyle(0x3e3e3e)
      //     .fillRect(0, row * cellHeight, tableWidth, sectionHeight);
      //   this.sections.push({ start: row, end: row + 9, graphics: section });
      // }
    }

    // Draw bids and tricks columns
    for (let col = 0; col < columns / 2; col++) {
      graphics.lineBetween(
        this.dealColWidth + this.cellWidth * (col + 1),
        0,
        this.dealColWidth + this.cellWidth * (col + 1),
        tableHeight
      );
    }

    // Draw first column (left edge)
    graphics.lineBetween(0, 0, 0, tableHeight);

    graphics.lineBetween(this.dealColWidth, 0, this.dealColWidth, tableHeight);

    // Style for each section of the column.
    const dealTextStyle = {
      fontSize: "20px",
      fill: "#ffffff",
      align: "center",
    };
    const pulkaPointsTextStyle = {
      fontSize: "20px",
      fill: "#ffff00",
      align: "center",
    };
    const predictionTextStyle = {
      fontSize: "20px",
      fill: "#ffffff",
      align: "center",
    };
    const jokerTextStyle = {
      fontSize: "20px",
      fill: "#ff0000",
      align: "center",
    };
    const pulkaTextStyle = {
      fontFamily: "Arial",
      fontSize: "28px",
      fontStyle: "bold",
      align: "center",
    };

    // Create the very first column only for deal numbers and points letters
    let pulka3Darigeba = 8;
    for (let row = 1; row < rows; row++) {
      if (row < 9) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            row,
            dealTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row === 9) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            "S",
            pulkaPointsTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row < 14) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            9,
            dealTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row === 14) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            "S",
            pulkaPointsTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row < 23) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            pulka3Darigeba--,
            dealTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row === 23) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            "S",
            pulkaPointsTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row < 28) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            9,
            dealTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row === 28) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            "S",
            pulkaPointsTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row === 29) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            "P",
            predictionTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row === 30) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            "T",
            pulkaPointsTextStyle
          )
          .setOrigin(0.5, 0.5);
      } else if (row === 31) {
        this.scene.add
          .text(
            this.dealColWidth / 2,
            this.cellHeight / 2 + this.cellHeight * row,
            "J",
            jokerTextStyle
          )
          .setOrigin(0.5, 0.5);
      }
    }

    // Create text objects for points. They will be regularly updated and may not be created every time with the update.
    // So, text objects will be created only once here and with the update just content will change.
    for (let row = 0; row < rows; row++) {
      // Putting points, total score, predictions, and bid texts in the same array;
      // they are in different rows, so they are not going to conflict. And I will style them differently.
      this.bidsTextArray[row] = [];

      // Points are in the same row as bids. So, I will use different array for points.
      this.pointsTextArray[row] = [];

      for (let col = 0; col < 4; col++) {
        if (row === 0) {
          const headerTextStyle = {
            fontSize: "24px",
            fill: "#ffffff",
            align: "center",
          };
          const headerText = this.scene.add
            .text(
              this.dealColWidth + this.cellWidth * (col + 0.5),
              this.cellHeight / 2,
              "",
              headerTextStyle
            )
            .setOrigin(0.5);
          this.bidsTextArray[row].push(headerText);
        } else if (
          // pulka points
          row === 9 ||
          row === 14 ||
          row === 23 ||
          row === 28 ||
          // predictions
          row === 29 ||
          // total score
          row === 30 ||
          // total Jokers
          row === 31
        ) {
          const text = this.scene.add
            .text(
              this.dealColWidth + this.cellWidth * (col + 0.5),
              this.cellHeight * (0.5 + row),
              "",
              pulkaTextStyle
            )
            .setOrigin(0.5, 0.5);
          this.bidsTextArray[row].push(text);
        } else {
          const bidTextStyle = {
            fontFamily: "Arial",
            fontSize: "26px",
            fill: "#ffffff",
            align: "center",
          };
          const bidText = this.scene.add
            .text(
              this.bidPadding +
                this.dealColWidth +
                col * this.cellWidth +
                this.bidCellWidth / 2,
              this.cellHeight * (0.5 + row),
              "",
              bidTextStyle
            )
            .setOrigin(0.5, 0.5);

          this.bidsTextArray[row].push(bidText);
          const pointsTextStyle = {
            fontSize: "26px",
            align: "center",
          };
          const trickPoinsText = this.scene.add
            .text(
              this.dealColWidth + (col + 1) * this.cellWidth - this.bidPadding,
              this.cellHeight * (0.5 + row),
              "",
              bidTextStyle
            )
            .setOrigin(1, 0.5);
          this.pointsTextArray[row].push(trickPoinsText);
        }
      }
    }

    // for (let col = 0; col < columns; col++) {
    //   if (col % 2 === 0) {
    //     graphics.lineBetween(
    //       headerCoCellWidth * 2 + (headerCoCellWidth + cellWidth) * (col / 2),
    //       0,
    //       headerCoCellWidth * 2 + (headerCoCellWidth + cellWidth) * (col / 2),
    //       tableHeight
    //     );
    //   } else {
    //     graphics.lineBetween(
    //       headerCoCellWidth + (headerCoCellWidth + cellWidth) * ((col + 1) / 2),
    //       0,
    //       headerCoCellWidth + (headerCoCellWidth + cellWidth) * ((col + 1) / 2),
    //       tableHeight
    //     );
    //   }
    // }

    // Add buttons to toggle sections
    // this.add
    //   .text(10, 320, "Toggle Section 1", { fontSize: "18px", fill: "#0f0" })
    //   .setInteractive()
    //   .on("pointerdown", () => this.toggleSection(0));

    // this.add
    //   .text(10, 360, "Toggle Section 2", { fontSize: "18px", fill: "#0f0" })
    //   .setInteractive()
    //   .on("pointerdown", () => this.toggleSection(1));
  }

  // addHeaders(columns, cellWidth) {
  //   const headerStyle = { fontSize: "10px", fill: "#ffffff", align: "center" };
  //   for (let i = 0; i < columns; i++) {
  //     this.scene.add
  //       .text(i * cellWidth + cellWidth / 2, 0, ``, headerStyle)
  //       .setOrigin(0.5, 0);
  //   }
  // }

  // TODO Make prediction to bolden highest and lowest if they will be deducted or added

  updateTable() {
    console.log(`Updating score sheet`);
    const players = gameState.public.players;
    let totalScoreRows = { 0: 9, 1: 14, 2: 23, 3: 28 };
    let incr = 1;
    for (let id in players) {
      let playersData = players[id];
      if (playersData.lineUp != null) {
        // Starting column with 0 index
        let col = playersData.lineUp - 1;
        this.bidsTextArray[0][col].setText(playersData.name);
        for (let i = 0; i < playersData.score.pulka.length; i++) {
          let numOfXishti = 0;
          let xishti = "___";
          let eggplant = "🍆";
          let pene = "==>";
          if (i === 0) incr = 1;
          else if (i === 1) incr = 10;
          else if (i === 2) incr = 15;
          else if (i === 3) incr = 24;
          for (let j = 0; j < playersData.score.pulka[i].bid.length; j++) {
            let row = j + incr;
            let trickPointsColor = "#ffffff";
            if (
              playersData.score.pulka[i].bid[j].bidMade !=
              playersData.score.pulka[i].bid[j].tricksWon
            ) {
              trickPointsColor = "#ffffff";
            }
            let _points = playersData.score.pulka[i].bid[j].points;
            if (_points < 0) {
              if (++numOfXishti >= 4) {
                xishti = eggplant;
              } else if (numOfXishti >= 3) {
                xishti = pene;
              }
              _points = xishti;
            }
            let _bidMade =
              playersData.score.pulka[i].bid[j].bidMade === 0
                ? "-"
                : playersData.score.pulka[i].bid[j].bidMade;
            this.bidsTextArray[row][col].setText(_bidMade);
            this.pointsTextArray[row][col]
              .setText(_points)
              .setColor(trickPointsColor);
            const strikethrough =
              playersData.score.pulka[i].bid[j].strikethrough;
            // Lift the underscores up so that they appear in the middle and make it bold
            if (_points === "___") {
              this.pointsTextArray[row][col]
                .setStyle({
                  fontStyle: "bold",
                })
                .setOrigin(1, 1);
            }
            if (strikethrough) {
              this.applyStrikethrough(this.pointsTextArray[row][col]);
            }
          }
          // So that pulka scores won't display unless the first "inPulkaRound" has played
          if (playersData.score.pulka[i].bid[0].points) {
            let _totalPoints = Number(
              playersData.score.pulka[i].totalPoints / 100
            ).toFixed(1);
            let premiaColor = playersData.score.pulka[i].premia
              ? "#006400"
              : "#ff0000";
            this.bidsTextArray[totalScoreRows[i]][col]
              .setText(_totalPoints)
              .setColor(premiaColor);
          }
        }

        // let predictionPoints =
        //   playersData.highestPoint == undefined
        //     ? "0"
        //     : playersData.highestPoint; //: (playersData.highestPoint / 100).toFixed(1);
        // this.bidsTextArray[29][col].setText(predictionPoints);

        let finalPoints =
          playersData.finalPoints == undefined
            ? ""
            : (playersData.finalPoints / 100).toFixed(1);
        this.bidsTextArray[29][col].setText(finalPoints);

        // Total Jokers
        //this.bidsTextArray[31][col].setText(playersData.totalJokers);
      }
    }
  }

  applyStrikethrough(textObject) {
    const textBounds = textObject.getBounds();

    // Create a Graphics object to draw the strikethrough line
    const graphics = this.scene.add.graphics();

    // Set the line style (color and thickness)
    graphics.lineStyle(2, 0xff0000, 1); // Red line, 2 pixels thick

    // Draw the strikethrough line at the vertical center of the text
    graphics.beginPath();
    graphics.moveTo(textBounds.x, textBounds.centerY); // Start from the left side of the text
    graphics.lineTo(textBounds.x + textBounds.width, textBounds.centerY); // Draw to the right side
    graphics.strokePath();
  }

  fillCell(row, column, color) {
    const graphics = this.scene.add.graphics();
    const x = this.dealColWidth + this.cellWidth * column;
    const y = this.cellHeight + this.cellHeight * row;
    graphics.fillStyle(color, 1);
    graphics.fillRect(x, y, this.cellWidth, this.cellHeight);
  }

  toggleSection(sectionIndex) {
    if (sectionIndex < this.sections.length) {
      const section = this.sections[sectionIndex];
      if (section.graphics.visible) {
        section.graphics.setVisible(false); // Hide section
      } else {
        section.graphics.setVisible(true); // Show section
      }
    }
  }
}
