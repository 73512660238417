import constants from "./constants";
import { LayoutManager } from "./LayoutManager";

export class JokerCallBanner {
  constructor(scene, x, y, text, options = {}) {
    this.scene = scene;
    this.layoutManager = new LayoutManager(scene);
    this.cardDisplayWidth =
      scene.scale.width * this.layoutManager.getLayout().cardScale;

    // Default options
    const defaultOptions = {
      width: this.cardDisplayWidth,
      height: 30,
      bubbleColor: 0xfff8dc,
      borderColor: 0x000000,
      borderWidth: 2,
      textStyle: {
        fontSize: "20px",
        color: "#000000",
        wordWrap: { width: 180 },
      },
    };

    // Merge default options with user options
    this.options = { ...defaultOptions, ...options };

    // Create a container for the speech bubble
    this.container = this.scene.add
      .container(x, y)
      .setDepth(11)
      .setVisible(false);

    // Create the bubble background using Graphics
    const bubble = this.scene.add.graphics();
    bubble.fillStyle(this.options.bubbleColor, 1);
    bubble.fillRoundedRect(0, 0, this.options.width, this.options.height, 10); // Rounded rectangle
    bubble.lineStyle(this.options.borderWidth, this.options.borderColor, 1);
    bubble.strokeRoundedRect(0, 0, this.options.width, this.options.height, 10);

    // Add the text
    const bubbleText = this.scene.add
      .text(
        this.options.width / 2,
        this.options.height / 2,
        text,
        this.options.textStyle
      )
      .setName("bubbleText")
      .setOrigin(0.5);

    // Add bubble and text to the container
    this.container.add([bubble, bubbleText]);

    // Position adjustment (move container to center the bubble)
    this.container.setSize(this.options.width, this.options.height);
    this.container.setPosition(
      x - this.options.width / 2,
      y - this.options.height / 2
    );
  }

  // Method to set text
  setText(newText) {
    const textObject = this.container.getByName("bubbleText");
    console.log(textObject);
    if (textObject) {
      textObject.setText(newText);
    }
  }

  // Method to toggle visibility
  setVisible(visible) {
    this.container.setVisible(visible);
  }

  // Method to move the speech bubble
  setPosition(x, y) {
    this.container.setPosition(x, y);
  }

  // Method to destroy the bubble
  destroy() {
    this.container.destroy();
  }
}
