import ZoneHandler from "./ZoneHandler.js";
import constants from "./constants";
import { LayoutManager } from "./LayoutManager.js";
import gameState from "./gameState.js";
import playerLocation from "./players.js";

export default class UIHandler {
  constructor(scene) {
    this.scene = scene;
    const layoutManager = new LayoutManager(scene);
    const canvasWidth = scene.scale.width;
    const canvasHeight = scene.scale.height;
    const tableCenter = { x: canvasWidth / 2, y: canvasHeight / 2 - 110 };
    const scaleFactor = canvasWidth / constants.REFERENCE_WIDTH; // Assume 800 is the reference width for your layout
    this.timerStartRad = -1.57;
    this.timerWidth = 0;
    this.timerRadius = 0;
    this.profileCircleRadius = 0;
    //this.cameras.main.backgroundColor = Phaser.Display.Color.HexStringToColor("#3498db");

    // this.gameBG = this.add.graphics();
    // this.gameBG.lineStyle(10, constants.colors["light"].lineAroundGame, 1);
    // this.gameBG //this.gameBG.fillStyle(0x690201, 1);
    //   .strokeRoundedRect(5, 5, canvasWidth - 10, canvasHeight - 10, 50);

    // this.gameBG //this.gameBG.fillStyle(0x690201, 1);
    //   .strokeRoundedRect(
    //     canvasWidth / 2 - 845,
    //     canvasHeight / 2 - 430,
    //     1690,
    //     850,
    //     50
    //   );

    // var ellipse = this.add.ellipse(constants.width / 2, constants.height - 50, 120, 60, 0xffffff);
    // var line = this.add.line(constants.width / 2, constants.height - 50, 0, 0, 0, 60, '#000000');

    // const centerX = scene.scale.width / 2;
    // const centerY = scene.scale.height / 2;

    this.buildContainers = () => {
      this.bottomContainer = scene.add
        .container(
          layoutManager.getLayout().bottom.x,
          layoutManager.getLayout().bottom.y
        )
        .setName("bottom");
      this.leftContainer = scene.add
        .container(
          layoutManager.getLayout().left.x,
          layoutManager.getLayout().left.y
        )
        .setName("left");
      this.topContainer = scene.add
        .container(
          layoutManager.getLayout().top.x,
          layoutManager.getLayout().top.y
        )
        .setName("top");
      this.rightContainer = scene.add
        .container(
          layoutManager.getLayout().right.x,
          layoutManager.getLayout().right.y
        )
        .setName("right");
      const containerArray = [];

      containerArray.push(this.bottomContainer);
      containerArray.push(this.leftContainer);
      containerArray.push(this.topContainer);
      containerArray.push(this.rightContainer);

      return containerArray;
    };

    this.buildDealerCircle = (x, y) => {
      const dealerContainer = scene.add.container(x, y);
      const circle = scene.add.circle(0, 0, 17, 0xffffff);
      circle.setStrokeStyle(6, 0xff0000);
      const dealerText = scene.add
        .text(0, 0, "D", {
          fontSize: 30,
          fontFamily: "Courier",
          fill: "#ff0000",
        })
        .setOrigin(0.5);
      dealerContainer.add([circle, dealerText]).setVisible(false);
      return dealerContainer;
    };

    this.buildPremiaCircle = (x, y) => {
      const premiaContainer = scene.add.container(x, y);
      const circle = scene.add.circle(0, 0, 20, 0x0b6623);
      circle.setStrokeStyle(4, 0xffffff);
      const pText = scene.add
        .text(0, 0, "P", {
          fontSize: 35,
          fontFamily: "Courier",
          fill: "#fff",
        })
        .setOrigin(0.5);
      premiaContainer.add([circle, pText]).setVisible(false);
      return premiaContainer;
    };

    this.buildRoundTable = () => {
      const tableWidth = 900;
      const tableHeight = 600;
      const tableOuterThickness = 30;
      const innerTableWidth = 860;
      const innerTableHeight = 560;
      const tableInnerThickness = 15;

      // size version 2
      //     const tableWidth = 1000;
      //     const tableHeight = 500;
      //     const tableOuterThickness = 30;
      //     const innerTableWidth = 960;
      //     const innerTableHeight = 460;
      //     const tableInnerThickness = 15;

      this.gameTable = scene.add.graphics();
      this.gameTable.lineStyle(tableOuterThickness, 0x110011);
      this.gameTable.fillStyle(0x064d28, 1);
      this.gameTable.fillRoundedRect(
        tableCenter.x - tableWidth / 2,
        tableCenter.y - tableHeight / 2,
        tableWidth,
        tableHeight,
        tableHeight / 2
      );

      this.gameTable.strokeRoundedRect(
        tableCenter.x - tableWidth / 2,
        tableCenter.y - tableHeight / 2,
        tableWidth,
        tableHeight,
        tableHeight / 2
      );

      this.innerTableRing = scene.add.graphics();
      this.innerTableRing.lineStyle(tableInnerThickness, 0xfff8dc);
      this.innerTableRing.strokeRoundedRect(
        tableCenter.x - innerTableWidth / 2,
        tableCenter.y - innerTableHeight / 2,
        innerTableWidth,
        innerTableHeight,
        innerTableHeight / 2
      );
    };

    this.buildTableLine = () => {
      const tableWidth = 900;
      const tableHeight = 600;
      const tableThickness = 15;

      this.gameTable = scene.add.graphics();
      this.gameTable.lineStyle(tableThickness, 0xfff8dc);
      this.gameTable.fillStyle(0x064d28, 1);
      this.gameTable.fillRect(
        tableCenter.x - tableWidth / 2,
        tableCenter.y - tableHeight / 2,
        tableWidth,
        tableHeight
      );

      this.gameTable.strokeRect(
        tableCenter.x - tableWidth / 2,
        tableCenter.y - tableHeight / 2,
        tableWidth,
        tableHeight,
        tableHeight / 2
      );
    };

    this.buildMobileBidsAndTricks = () => {
      const tricksPlaceHolderWidth = 72;
      const tricksPlaceHolderHeight = 72;
      const tricksPlaceHolderBorderRadius = 36;

      this.containerArray = this.buildContainers();

      // reactIconsGroup.addMultiple([c1, c2, c3, c4, c5, c6, c7, c8, c9]);

      // let actionsCircle = new Phaser.Geom.Circle(
      //   constants.location.mySelf.trick.x,
      //   constants.location.mySelf.trick.y,
      //   60
      // );

      // Phaser.Actions.PlaceOnCircle(
      //   reactIconsGroup.getChildren(),
      //   actionsCircle,
      //   -0.2,
      //   -3.34
      // );

      // **** SQUARE PROFILES ****
      this.containerArray.forEach((cont) => {
        // Just a placeloder rectangle
        const profile = scene.add.rectangle(0, 0, 50, 50, 0x000000);
        const hitArea = scene.add.rectangle(0, -90, 110, 30);
        const name = scene.add
          .text(0, 0, "")
          .setOrigin(0.5, 1)
          .setFontSize(18)
          .setColor("#fff");
        const bidBox = scene.add.rectangle(
          -12,
          12,
          22,
          22,
          constants.colors["light"].bidBox
        );
        const trickBox = scene.add.rectangle(
          12,
          12,
          22,
          22,
          constants.colors["light"].trickBox
        );
        // bidBox.setStrokeStyle(1, 0xefc53f);
        // trickBox.setStrokeStyle(1, 0xefc53f);
        const bidBoxText = scene.add
          .text(-12, 12, "", {
            fill: "#000",
            fontSize: 24,
            fontFamily: "monospace",
            fontStyle: "bold",
          })
          .setOrigin(0.5);
        const trickBoxText = scene.add
          .text(12, 12, "", {
            fill: "#000",
            fontSize: 24,
            fontFamily: "monospace",
            fontStyle: "bold",
          })
          .setOrigin(0.5)
          .setName("trickBox");
        const placement = scene.add
          .text(0, 0, "", {
            fill: "#f00",
            backgroundColor: "#FFFFFF",
            fontSize: 64,
          })
          .setOrigin(0.5);
        if (cont.name != "bottom") {
          // reactProfilesGroup.add(hitArea);
        }

        cont.add([
          profile,
          name,
          bidBox,
          trickBox,
          bidBoxText,
          trickBoxText,
          placement,
          hitArea,
        ]);
        //cont.angle = 45;
      });
    };

    this.buildVerticalBidsAndTricks = () => {
      this.containerArray = this.buildContainers();
      this.containerArray.forEach((cont) => {
        // Just a placeloder rectangle
        const profile = scene.add.rectangle(0, 0, 104, 29, 0x000000);
        const hitArea = scene.add.rectangle(0, -90, 110, 30);
        const name = scene.add
          .text(-25, 0, "")
          .setOrigin(0.5)
          .setFontSize(18)
          .setColor("#fff");
        const bidAndTrickBox = scene.add.rectangle(
          25,
          0,
          50,
          25,
          constants.colors["light"].bidBox
        );
        const bidAndTrickBoxText = scene.add
          .text(25, 0, "", {
            fill: "#000",
            fontSize: 24,
            fontFamily: "monospace",
            fontStyle: "bold",
          })
          .setName("bidAndTrickBoxText")
          .setOrigin(0.5);
        const placement = scene.add
          .text(0, 0, "", {
            fill: "#f00",
            backgroundColor: "#FFFFFF",
            fontSize: 64,
          })
          .setOrigin(0.5);
        if (cont.name == "left") {
          cont.angle = 270;
        } else if (cont.name == "right") {
          cont.angle = 90;
        }
        const dealer = this.buildDealerCircle(65, 0).setName("dealer");
        cont.add([
          profile,
          name,
          bidAndTrickBox,
          bidAndTrickBoxText,
          placement,
          hitArea,
          dealer,
        ]);
      });
    };

    this.buildDesktopBidsAndTricks = () => {
      // const imagePlaceHolderWidth = constants.DESKTOP_IMAGE_WIDTH;
      // const imagePlaceHolderHeight = imagePlaceHolderWidth;
      // const imagePlaceHolderRadius = imagePlaceHolderWidth / 2;

      this.containerArray = this.buildContainers();
      this.containerArray.forEach((cont) => {
        // const imagePlaceHolder = scene.add.graphics();
        // imagePlaceHolder.fillStyle(0x000000, 0.5);
        // imagePlaceHolder.fillRoundedRect(
        //   -imagePlaceHolderWidth / 2,
        //   -imagePlaceHolderHeight / 2,
        //   imagePlaceHolderWidth,
        //   imagePlaceHolderHeight,
        //   imagePlaceHolderRadius
        // );

        const avatar = scene.add
          .image(0, 0, "round-avatar")
          .setScale(0.3)
          .setName("avatar");

        const circleAroundProfileImage = scene.add.graphics();
        circleAroundProfileImage.lineStyle(10, 0x000000, 0.3);

        //  Without this the arc will appear closed when stroked
        circleAroundProfileImage.beginPath();

        // arc (x, y, radius, startAngle, endAngle, anticlockwise)
        circleAroundProfileImage.arc(
          0,
          0,
          60,
          Phaser.Math.DegToRad(0), //for top arc only 340
          Phaser.Math.DegToRad(360), //for top arc only 200
          false
        );

        //  Uncomment this to close the path before stroking
        // graphics.closePath();

        // circleAroundProfileImage.strokePath();

        const name = scene.add
          .text(0, 30, "", {
            fontSize: 25,
            color: "#000000",
            fixedWidth: 100,
            align: "center",
            backgroundColor: "#f2f2f2",
          })
          .setOrigin(0.5)
          .setName("name")
          .setVisible(false);

        // let bidBox = scene.add.rectangle(-29, 0, 13, 17, 0x000000, 0.5);
        // let trickBox = scene.add.rectangle(29, 0, 13, 17, 0x000000, 0.5);
        // let bidBox = scene.add.circle(-40, 0, 18, 0x000000, 0.5);
        // let trickBox = scene.add.circle(40, 0, 18, 0x000000, 0.5);
        // bidBox.setStrokeStyle(1, 0xefc53f);
        // trickBox.setStrokeStyle(1, 0xefc53f);

        let x = 0;
        let y = 90;
        if (cont.name === "bottom") {
          x = 0;
          y = -90;
        } else if (cont.name === "left") {
          x = 110;
          y = 0;
        } else if (cont.name === "right") {
          x = -110;
          y = 0;
        }

        const bidAndTrickBox = scene.add.rectangle(x, y, 80, 40, 0xf2f2f2);

        const bidAndTrickBoxText = scene.add
          .text(x, y, "", {
            fill: "#000",
            fontSize: 36,
            fontFamily: "monospace",
            fontStyle: "bold",
          })
          .setName("bidAndTrickBoxText")
          .setOrigin(0.5);
        // let bidBoxText = scene.add
        //   .text(-29, 0, "", {
        //     fill: constants.colors["light"].bidNum,
        //     fontSize: 16,
        //     fontFamily: "monospace",
        //     fontStyle: "bold",
        //   })
        //   .setOrigin(0.5);
        // let trickBoxText = scene.add
        //   .text(29, 0, "", {
        //     fill: constants.colors["light"].trickNum,
        //     fontSize: 16,
        //     fontFamily: "monospace",
        //     fontStyle: "bold",
        //   })
        //   .setOrigin(0.5);
        const hitArea = scene.add.rectangle(0, -100, 120, 25);

        // A colored circle to indicate player is doing premia. Not using now
        //const premia = scene.add.circle(-65, 90, 20).setName("premia");

        const decorRing = scene.add.graphics();
        this.drawPremiaRing(decorRing, 0xffffff);

        const timer = scene.add.graphics().setName("timer");

        //const dealer = this.buildDealerCircle(65, 90).setName("dealer");
        const dealer = this.buildDealerCircle(50, -35).setName("dealer");
        const premia = this.buildPremiaCircle(-50, -35).setName("premia");
        cont
          .add([
            //imagePlaceHolder,
            avatar,
            name,
            bidAndTrickBox,
            bidAndTrickBoxText,
            circleAroundProfileImage,
            hitArea,
            decorRing,
            timer,
            premia,
            dealer,
          ])
          .setDepth(1); // setting depth so that image won't cover name
      });
    };

    this.buildCircularProfiles = () => {
      this.containerArray = this.buildContainers();
      this.containerArray.forEach((cont) => {
        const centerX = 0;
        const centerY = 0;
        const imageRadius = constants.DESKTOP_IMAGE_WIDTH / 2;
        const decorRingWidth = 4;
        const arcWidth = 34;
        this.profileCircleRadius = imageRadius + decorRingWidth * 2 + arcWidth;
        const arcRadius =
          (constants.DESKTOP_IMAGE_WIDTH + arcWidth) / 2 + decorRingWidth;

        const name = this.makeCircularText(230, 80, arcRadius, "").setName(
          "name"
        );

        const dealerText = this.makeCircularText(
          -27,
          55,
          arcRadius,
          "DLR"
        ).setName("dealer");

        const bonusText = this.makeCircularText(155, 55, arcRadius, "BNS");

        const bidAndTrickBoxText = scene.add
          .text(0, 73, "", {
            fill: "#fff",
            fontSize: 34,
            fontFamily: "monospace",
            fontStyle: "bold",
          })
          .setName("bidAndTrickBoxText")
          .setOrigin(0.5);

        const nameArc = this.buildArc(326, 214, arcWidth, arcRadius, 0x214c6d);
        const dealerBkgr = this.buildArc(
          31,
          329,
          arcWidth,
          arcRadius,
          0x214c6d
        );
        const dealer = this.buildArc(31, 329, arcWidth, arcRadius, 0xff0000);
        const bidNtrick = this.buildArc(
          146,
          34,
          arcWidth,
          arcRadius,
          0x214c6d
        ).setName("bidNtrickArc");
        const bidNtrickGreen = this.buildArc(
          146,
          34,
          arcWidth,
          arcRadius,
          0x0b6623
        )
          .setVisible(false)
          .setName("bidNtrickArcGreen");
        const bidNtrickRed = this.buildArc(
          146,
          34,
          arcWidth,
          arcRadius,
          0xff0000
        )
          .setVisible(false)
          .setName("bidNtrickArcRed");
        const bonus = this.buildArc(211, 149, arcWidth, arcRadius, 0x0b6623);
        const strikethrough = this.buildArc(206, 154, 5, arcRadius, 0xff0000)
          .setVisible(false)
          .setName("premia");

        const dealerContainer = this.scene.add
          .container(0, 0)
          .setVisible(false)
          .setName("dealer");
        dealerContainer.add([dealer, dealerText]);
        // Profile Circle
        const profileCircle = scene.add.graphics().setName("profileCircle");
        profileCircle.fillStyle(0xad8e6, 1); // Dark gray background
        profileCircle.fillCircle(centerX, centerY, this.profileCircleRadius); // Circle with radius 80

        // Placeholder Image in the Center
        const imagePlaceholder = scene.add.graphics();
        imagePlaceholder.fillStyle(0xcccccc, 1); // Light gray placeholder
        imagePlaceholder.fillCircle(centerX, centerY, imageRadius); // Circle with radius 50

        // Draw the circle outline to create the ring effect
        // const decorRing = scene.add.graphics();
        // decorRing.lineStyle(decorRingWidth, 0xff0000);
        // decorRing.strokeCircle(0, 0, 57); // Adjust position if needed

        const timer = scene.add.graphics().setName("timer");
        this.timerWidth = 10;
        this.timerRadius = this.profileCircleRadius + 5;

        cont.add([
          profileCircle,
          imagePlaceholder,
          nameArc,
          name,
          dealerBkgr,
          dealerContainer,
          bonus,
          // decorRing,
          bidNtrick,
          bidNtrickGreen,
          bidNtrickRed,
          bidAndTrickBoxText,
          //dealerText,
          bonusText,
          strikethrough,
          timer,
        ]);
      });
    };

    this.buildPortraitProfiles = () => {
      this.containerArray = this.buildContainers();
      this.containerArray.forEach((cont) => {
        // const centerX = scene.scale.width / 2;
        // const centerY = scene.scale.height / 2;

        const centerX = 0;
        const centerY = 0;

        // Profile Box
        const boxWidth = 140;
        const boxHeight = 220;

        // Outer Rounded Rectangle for Profile Box
        const profileBox = scene.add.graphics();
        profileBox.fillStyle(0x2a2a2a, 1); // Dark gray background
        profileBox.fillRoundedRect(
          centerX - boxWidth / 2,
          centerY - boxHeight / 2,
          boxWidth,
          boxHeight,
          20
        );

        // Timer Bar
        const timerBar = scene.add.graphics().setName("timer");
        const timerBarWidth = boxWidth - 40;
        const timerBarHeight = 10;
        const timerBarX = centerX - timerBarWidth / 2;
        const timerBarY = centerY - boxHeight / 2 + 10;
        timerBar.fillStyle(0xf2f2f2, 1); // Red timer bar
        timerBar.fillRect(timerBarX, timerBarY, timerBarWidth, timerBarHeight);

        // Image Placeholder
        const imagePlaceholder = scene.add.graphics();
        const imagePlaceholderWidth = 120;
        const imagePlaceholderHeight = 120;
        const imagePlaceholderX = centerX - imagePlaceholderWidth / 2;
        const imagePlaceholderY = timerBarY + 20; // Positioned below the timer bar
        imagePlaceholder.fillStyle(0xcccccc, 1); // Light gray color
        imagePlaceholder.fillRoundedRect(
          imagePlaceholderX,
          imagePlaceholderY,
          imagePlaceholderWidth,
          imagePlaceholderHeight,
          10
        );

        // Circles Underneath the Image
        const circleRadius = 20;
        const circleSpacing = 40;
        const circlesStartY = imagePlaceholderY + imagePlaceholderHeight + 45; // Positioned below the image
        const circleLabels = ["Bid", "Won", "Run"];
        const textStyle = {
          fontSize: "16px",
          fontFamily: "Arial",
          color: "#ffffff", // White text
        };

        // const bid = scene.add
        //   .circle(
        //     centerX - circleSpacing + 0 * circleSpacing,
        //     circlesStartY,
        //     circleRadius,
        //     0xf2f2f2 // 0x444444
        //   )
        //   .setName("bid");
        // const won = scene.add
        //   .circle(
        //     centerX - circleSpacing + 1 * circleSpacing,
        //     circlesStartY,
        //     circleRadius,
        //     0xf2f2f2
        //   )
        //   .setName("won");
        // const run = scene.add
        //   .circle(
        //     centerX - circleSpacing + 2 * circleSpacing,
        //     circlesStartY,
        //     circleRadius,
        //     0xf2f2f2
        //   )
        //   .setName("run");

        // // Labels above the circles
        // const bidLabel = scene.add
        //   .text(
        //     centerX - circleSpacing + 0 * circleSpacing,
        //     circlesStartY - 30,
        //     "Bid",
        //     textStyle
        //   )
        //   .setOrigin(0.5);
        // const wonLabel = scene.add
        //   .text(
        //     centerX - circleSpacing + 1 * circleSpacing,
        //     circlesStartY - 30,
        //     "Won",
        //     textStyle
        //   )
        //   .setOrigin(0.5);
        // const runLabel = scene.add
        //   .text(
        //     centerX - circleSpacing + 2 * circleSpacing,
        //     circlesStartY - 30,
        //     "Run",
        //     textStyle
        //   )
        //   .setOrigin(0.5);
        cont.add([profileBox, timerBar, imagePlaceholder]).setDepth(1);

        for (let i = 0; i < 3; i++) {
          const circleX = centerX - circleSpacing + i * circleSpacing;
          const circleY = circlesStartY;

          // Circle
          const circle = scene.add.circle(
            circleX,
            circleY,
            circleRadius,
            0x444444
          );
          const text = scene.add
            .text(circleX, circleY, "", {
              fill: "#fff",
              fontSize: 36,
              fontFamily: "monospace",
              fontStyle: "bold",
            })
            .setName(circleLabels[i])
            .setOrigin(0.5);
          const label = scene.add
            .text(circleX, circleY - 30, circleLabels[i], textStyle)
            .setOrigin(0.5); // Label above the circle
          cont.add([circle, text, label]).setDepth(1);
        }
      });
    };

    this.buildLandscapeProfiles = () => {
      this.containerArray = this.buildContainers();
      this.containerArray.forEach((cont) => {
        // const centerX = scene.scale.width / 2;
        // const centerY = scene.scale.height / 2;

        const centerX = 0;
        const centerY = 0;

        // Profile Box
        const boxWidth = 220;
        const boxHeight = 160;

        // Outer Rounded Rectangle for Profile Box
        const profileBox = scene.add.graphics();
        profileBox.fillStyle(0x2a2a2a, 1); // Dark gray background
        profileBox.fillRoundedRect(
          centerX - boxWidth / 2,
          centerY - boxHeight / 2,
          boxWidth,
          boxHeight,
          20
        );

        // Timer Bar
        const timerBar = scene.add.graphics();
        const timerBarWidth = boxWidth - 40;
        const timerBarHeight = 10;
        const timerBarX = centerX - timerBarWidth / 2;
        const timerBarY = centerY - boxHeight / 2 + 10;
        timerBar.fillStyle(0xf2f2f2, 1); // Red timer bar
        timerBar.fillRect(timerBarX, timerBarY, timerBarWidth, timerBarHeight);

        // Image Placeholder
        const imagePlaceholder = scene.add.graphics();
        const imagePlaceholderWidth = 120;
        const imagePlaceholderHeight = 120;
        const imagePlaceholderX = centerX - boxWidth / 2 + 10;
        const imagePlaceholderY = timerBarY + 20; // Positioned below the timer bar
        imagePlaceholder.fillStyle(0xcccccc, 1); // Light gray color
        imagePlaceholder.fillRoundedRect(
          imagePlaceholderX,
          imagePlaceholderY,
          imagePlaceholderWidth,
          imagePlaceholderHeight,
          10
        );

        // Circles Underneath the Image
        const circleRadius = 20;
        const circleSpacing = 40;
        const circlesStartX = imagePlaceholderX + imagePlaceholderWidth + 25; // Positioned below the image
        const circleLabels = ["Bid", "Won", "Run"];
        const textStyle = {
          fontSize: "16px",
          fontFamily: "Arial",
          color: "#ffffff", // White text
        };

        cont.add([profileBox, timerBar, imagePlaceholder]).setDepth(1);

        for (let i = 0; i < 3; i++) {
          const circleX = circlesStartX;
          const circleY = centerY + i * circleSpacing;

          // Circle
          const circle = scene.add.circle(
            circleX + circleSpacing,
            circleY - circleSpacing + 10,
            circleRadius,
            0x444444
          );
          const text = scene.add
            .text(circleX, circleY, "", {
              fill: "#fff",
              fontSize: 36,
              fontFamily: "monospace",
              fontStyle: "bold",
            })
            .setName(circleLabels[i])
            .setOrigin(0.5);
          const label = scene.add
            .text(circleX, circleY - 30, circleLabels[i], textStyle)
            .setOrigin(0.5); // Label above the circle
          cont.add([circle, text, label]).setDepth(1);
        }
      });
    };

    this.buildMobileUI = () => {
      // this.buildRoundTable();
      //this.squareProfiles();
      // this.buildTableLine();
      // this.buildMobileBidsAndTricks();
      this.buildVerticalBidsAndTricks();
    };

    this.builDesktopUI = () => {
      // this.buildRoundTable();
      // this.squareProfiles();
      // this.buildTableLine();
      // this.buildDesktopBidsAndTricks();
      // this.buildPortraitProfiles();
      this.buildCircularProfiles();
      // this.buildLandscapeProfiles();
    };
  }

  findItemByName(container, name) {
    return container.getAll().find((child) => child.name === name) || null;
  }

  setDealerSign() {
    const position = playerLocation[gameState.public.dealerId]?.position;
    if (position) {
      for (let cont of this.containerArray) {
        // Enable to redraw the dealer profile circle in a red color
        // const profileCircle = this.findItemByName(cont, "profileCircle");
        if (cont.name === position) {
          console.log(`Showing dealer sign for userId: ${position}`);
          const dealer = this.findItemByName(cont, "dealer");
          dealer.visible = true;

          // Enable to redraw the dealer profile circle in a red color
          //   profileCircle.clear();
          //   profileCircle.fillStyle(0xb4171e, 1); // Set the new color
          //   profileCircle.fillCircle(0, 0, this.profileCircleRadius); // Redraw the same shape
          // } else {
          //   profileCircle.clear();
          //   profileCircle.fillStyle(0xad8e6, 1); // Set the new color
          //   profileCircle.fillCircle(0, 0, this.profileCircleRadius); // Redraw the same shape
        }
      }
    }
  }

  // Function to draw the ring with a specified color
  drawPremiaRing(premiaRing, color) {
    // Clear any previous drawing on the graphics object
    premiaRing.clear();

    // Set the new line style with the specified color
    premiaRing.lineStyle(10, color);

    // Draw the circle outline to create the ring effect
    premiaRing.strokeCircle(0, 0, 60); // Adjust position if needed
  }

  clearTimers() {
    for (let cont of this.containerArray) {
      const timer = this.findItemByName(cont, "timer");
      timer.clear();
    }
  }

  setCircleTimerValue(timer, endRad) {
    console.log("In set cricle timer value");
    let _endRad = this.timerStartRad + 6.28 * endRad;
    timer.clear();
    timer.lineStyle(this.timerWidth, 0xe4f87f, 1);
    timer.beginPath();
    timer.arc(0, 0, this.timerRadius, this.timerStartRad, _endRad, false);
    timer.strokePath();
  }

  updatePremiaSign() {
    console.log("Updating premia sign");
    console.log(gameState.public.inPulkaRound);
    if (gameState.public.dealerId != null) {
      const players = gameState.public.players;
      Object.keys(players).forEach((playerId) => {
        const doingPremia =
          players[playerId].score.pulka[gameState.public.pulka - 1].premia;
        const premiaSign = this.findItemByName(
          playerLocation[playerId].container,
          "premia"
        );

        if (doingPremia) {
          console.log("Player is on winning streak");
          premiaSign.setVisible(false);
        } else {
          console.log("Player is not on winning streak");
          premiaSign.setVisible(true);
        }
      });
    }
  }

  makeCircleWithLabel(circleX, circleY) {
    // const circleX = centerX - circleSpacing + i * circleSpacing;
    // const circleY = circlesStartY;
    const circleRadius = 20;

    const textStyle = {
      fontSize: "16px",
      fontFamily: "Arial",
      color: "#ffffff", // White text
    };

    // Circle
    const circle = scene.add.circle(circleX, circleY, circleRadius, 0x444444);
    return circle;
  }

  buildArc(startAngle, endAngle, arcWidth, radius, color) {
    const arc = this.scene.add.graphics();
    const arcContainer = this.scene.add.container(0, 0);
    arc.lineStyle(arcWidth, color, 1);
    arc.beginPath();
    arc.arc(
      0,
      0,
      radius,
      Phaser.Math.DegToRad(startAngle), //for top arc only 340
      Phaser.Math.DegToRad(endAngle), //for top arc only 200
      true
    );
    arc.strokePath();
    // const dealerText = this.scene.add
    //   .text(0, 65, "D", {
    //     font: "bold 24px Courier",
    //     fill: "#ffffff",
    //   })
    //   .setOrigin(0.5);
    // arcContainer.add([dealerArc, dealerText]).setVisible(true);
    return arc;
    return arcContainer;
  }

  makeCircularText(startAngle, step, radius, text) {
    // Create a container to hold the Dealer circular text
    const circularTextContainer = this.scene.add.container(0, 0);

    // Circle parameters
    const _startAngle = Phaser.Math.DegToRad(startAngle); // Start angle (top of circle)

    // Calculate angle step for each character
    const angleStep = Phaser.Math.DegToRad(step / text.length);

    for (let i = 0; i < text.length; i++) {
      const char = text[i];

      // Calculate position for each character
      const angle = _startAngle + i * angleStep;
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;

      // Create the character as a text object
      const charText = this.scene.add.text(x, y, char, {
        font: "bold 27px Courier",
      });

      // Set the origin and rotation of the character
      charText.setOrigin(0, 0.5);
      charText.setRotation(angle + Phaser.Math.DegToRad(90));

      // Add the character to the container
      circularTextContainer.add(charText);
    }
    return circularTextContainer;
  }

  setName(container, text) {
    container.removeAll(true);
    // Create a container to hold the Dealer circular text

    // Circle parameters
    const radius = 76; // Radius of the circle
    // Calculate total arc covered by the text
    const charSpacing = Phaser.Math.DegToRad(18); // Spacing between characters
    const totalArc = charSpacing * (text.length - 1); // Total arc span

    // Start angle to center the text at the top of the circle
    const startAngle = Phaser.Math.DegToRad(-90) - totalArc / 2;

    for (let i = 0; i < text.length; i++) {
      const char = text[i];

      // Calculate position for each character
      const angle = startAngle + i * charSpacing;
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;

      // Create the character as a text object
      const charText = this.scene.add.text(x, y, char, {
        font: "bold 36px Courier",
        color: "#fff",
      });

      // Set the origin and rotation of the character
      charText.setOrigin(0.5, 0.5);
      charText.setRotation(angle + Phaser.Math.DegToRad(90));

      // Add the character to the container
      container.add(charText);
    }
    return container;
  }
}
