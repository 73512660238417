import GameScene from "./scenes/GameScene.js";
import GameOver from "./scenes/GameOver.js";
import MainMenu from "./scenes/MainMenu.js";
import SoloPracticeScene from "./scenes/SoloPracticeScene.js";

const config = {
  type: Phaser.AUTO,
  backgroundColor: "#1a374d", //"#1A374D", //"#bdb7a5", //"#8B0000", //#132939
  width: 1920,
  height: 1080,
  // width: window.innerWidth,
  // height: window.innerHeight,
  parent: "game",
  scene: [MainMenu, GameScene, GameOver, SoloPracticeScene],
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH,
  },

  // We need to add this dom configuration so that we are able to display html objects inside the phaser scenes.
  dom: {
    createContainer: true,
  },
};

// export const canvasWidth = config.scale.width;
// export const canvasHeight = config.scale.height;

export default config;
