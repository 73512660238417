import constants from "./constants";

export class LayoutManager {
  constructor(scene) {
    this.canvasWidth = scene.scale.width;
    this.canvasHeight = scene.scale.height;
    const cardHeightWidthRatio = constants.CARD_HEIGHT_TO_WIDTH_RATIO;
    const portraitCardScale = constants.MOBILE_CARD_SCALE;
    const landscapeCardScale = constants.DESKTOP_CARD_SCALE;
    const cardDisplayWidth = this.canvasWidth * portraitCardScale;
    const landscapeCardDisplayWidth = this.canvasWidth * landscapeCardScale;
    const mobilePadding = constants.MOBILE_PADDING;
    const portraitScoreTableHeight = 110;
    const landscapePlayAreaWidth = this.canvasWidth * 0.6;
    const landscapePlayAreaHeight =
      this.canvasHeight -
      (landscapeCardDisplayWidth * cardHeightWidthRatio +
        mobilePadding * 3 -
        constants.DESKTOP_IMAGE_HEIGHT * 2);

    // Center of the card play are
    const landscapePlayAreaCenter = {
      x: this.canvasWidth / 2,
      y:
        (this.canvasHeight -
          landscapeCardDisplayWidth * cardHeightWidthRatio -
          constants.DESKTOP_PADDING * 2) /
        2,
    };

    const landscapePlayAreaLeftEdge = this.canvasWidth * 0.2;

    const portraitPlayAreaWidth = this.canvasWidth;
    const portraitPlayAreaHeight =
      this.canvasHeight -
      (landscapeCardDisplayWidth * 2 * cardHeightWidthRatio +
        mobilePadding * 4) -
      portraitScoreTableHeight;

    // Center of the card play are
    const portraitPlayAreaCenter = {
      x: portraitPlayAreaWidth / 2,
      y: portraitScoreTableHeight + mobilePadding + portraitPlayAreaHeight / 2,
    };

    const portraitPlayAreaBottomEdge =
      portraitScoreTableHeight + portraitPlayAreaHeight;

    // this.gameBG = scene.add.graphics();
    // this.gameBG.lineStyle(3, 0x000000, 1);
    // this.gameBG.strokeRect(
    //   landscapePlayAreaLeftEdge,
    //   0,
    //   landscapePlayAreaWidth,
    //   landscapePlayAreaHeight
    // );

    // this.gameBG.strokeRect(
    //   0,
    //   110,
    //   portraitPlayAreaWidth,
    //   portraitPlayAreaHeight
    // );

    this.layout = {
      landscape: {
        // Define landscape positions
        button: { x: 400, y: 300 },
        trump: {
          x: landscapePlayAreaLeftEdge + constants.DESKTOP_PADDING,
          y: constants.DESKTOP_PADDING,
        },
        bottom: {
          x: landscapePlayAreaCenter.x,
          y:
            this.canvasHeight -
            landscapeCardDisplayWidth * cardHeightWidthRatio -
            constants.DESKTOP_IMAGE_HEIGHT -
            constants.DESKTOP_PADDING -
            constants.DESKTOP_PADDING -
            20,
        },
        left: {
          x:
            landscapePlayAreaCenter.x -
            (this.canvasHeight / 2 -
              constants.DESKTOP_PADDING -
              constants.DESKTOP_IMAGE_HEIGHT / 2),
          y: landscapePlayAreaCenter.y,
        },
        top: {
          x: landscapePlayAreaCenter.x,
          y:
            constants.DESKTOP_PADDING + constants.DESKTOP_IMAGE_HEIGHT / 2 + 60,
        },
        right: {
          x:
            landscapePlayAreaCenter.x +
            (this.canvasHeight / 2 -
              constants.DESKTOP_PADDING -
              constants.DESKTOP_IMAGE_HEIGHT / 2),
          y: landscapePlayAreaCenter.y,
        },
        lastTrick: {
          x:
            landscapePlayAreaLeftEdge +
            landscapePlayAreaWidth -
            landscapeCardDisplayWidth -
            constants.DESKTOP_PADDING,
          y: constants.DESKTOP_PADDING,
        },
        jokerHighCardsCall: {
          x: this.canvasWidth / 2,
          y: landscapePlayAreaCenter.y - 60,
        },
        jokerSuiteTakesCall: {
          x: this.canvasWidth / 2,
          y: landscapePlayAreaCenter.y + 60,
        },
        jokerOver: {
          x: this.canvasWidth / 2 - 60,
          y: landscapePlayAreaCenter.y + 160,
        },
        jokerUnder: {
          x: this.canvasWidth / 2 + 60,
          y: landscapePlayAreaCenter.y + 160,
        },
        tableCenter: {
          x: this.canvasWidth / 2,
          y:
            (this.canvasHeight -
              landscapeCardDisplayWidth * cardHeightWidthRatio -
              constants.DESKTOP_PADDING) /
            2,
        },
        playAreaCenter: {
          x: landscapePlayAreaCenter.x,
          y: landscapePlayAreaCenter.y,
        },
        padding: constants.DESKTOP_PADDING,
        speechBubble: {
          y: 240,
        },
        cardScale: 0.065,
      },
      portrait: {
        // Define portrait positions
        button: { x: 200, y: 150 },
        trump: { x: 0, y: portraitScoreTableHeight + mobilePadding },
        bottom: {
          x: portraitPlayAreaCenter.x,
          y: portraitScoreTableHeight + portraitPlayAreaHeight - 15,
        },
        left: {
          x: 15,
          y: portraitPlayAreaCenter.y,
        },
        top: {
          x: portraitPlayAreaCenter.x,
          y: portraitScoreTableHeight + mobilePadding + 15,
        },
        right: {
          x: portraitPlayAreaWidth - 15,
          y: portraitPlayAreaCenter.y,
        },
        lastTrick: {
          x: this.canvasWidth - cardDisplayWidth,
          y: portraitScoreTableHeight + mobilePadding,
        },
        jokerHighCardsCall: {
          x: this.canvasWidth / 2,
          y: this.canvasHeight / 2 - 60,
        },
        jokerSuiteTakesCall: {
          x: this.canvasWidth / 2,
          y: this.canvasHeight / 2 + 60,
        },
        jokerOver: {
          x: this.canvasWidth / 2 - 60,
          y: this.canvasHeight / 2 + 100,
        },
        jokerUnder: {
          x: this.canvasWidth / 2 + 60,
          y: this.canvasHeight / 2 + 100,
        },
        tableCenter: {
          x: this.canvasWidth / 2,
          y:
            (this.canvasHeight -
              (cardDisplayWidth * cardHeightWidthRatio + mobilePadding) * 2 -
              105) /
            2,
        },
        playAreaCenter: {
          x: portraitPlayAreaCenter.x,
          y: portraitPlayAreaCenter.y,
        },
        padding: 8,
        speechBubble: {
          y: portraitScoreTableHeight + mobilePadding + 45,
        },
        cardScale: 0.15,
      },
    };
  }

  // FIXME MOBILE - do .portrait instead of .landscape
  getLayout() {
    return window.innerWidth > window.innerHeight
      ? this.layout.landscape
      : this.layout.landscape; // .portrait
  }
}
