export default {
  REFERENCE_WIDTH: 1920, // Base width for scaling
  REFERENCE_HEIGHT: 1080, // Base height for scaling (optional)
  diamonds: "♦",
  clubs: "♣",
  hearts: "♥",
  spades: "♠",
  DESKTOP_IMAGE_WIDTH: 110,
  DESKTOP_IMAGE_HEIGHT: 110,
  DESKTOP_PADDING: 20,
  MOBILE_PADDING: 8,
  MOBILE_CARD_SCALE: 0.15,
  DESKTOP_CARD_SCALE: 0.06,
  CARD_HEIGHT_TO_WIDTH_RATIO: 1.52,
  JOKER_CALL_BACKGROUND_COLOR: "#63090e",
  THINK_TIME: 20,
  DESKTOP_BID_BUTTON_RADIUS: 40,
  colors: {
    light: {
      background: 0x132939,
      profileFrame: 0x1a374d,
      profileFrameText: "#1a374d",
      bidBox: 0xffffff,
      trickBox: 0xffffff,
      bidNum: "#999",
      trickNum: "#999",
      disabledCard: 0xbcbcb6,
      bidCircle: 0x000000,
      bidCircleStroke: 0xefc53f,
      lineAroundGame: 0x1a374d,
    },
    dark: {
      background: 0x000000,
      profileFrame: 0x1a374d,
      profileFrameText: "#1a374d",
      bidBox: 0x000000,
      trickBox: 0x000000,
      bidNum: "#fff",
      trickNum: "#fff",
      disabledCard: 0xbcbcb6,
      bidCircle: 0x000000,
      bidCircleStroke: 0xefc53f,
      lineAroundGame: 0x1a374d,
    },
  },
};
