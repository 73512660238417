import ScoreSheetFull from "../ScoreSheetFull.js";

export default class GameOver extends Phaser.Scene {
  constructor() {
    super({
      key: "GameOver",
    });
  }

  init(data) {
    this.players = data.players;
    this.socket = data.socket;
  }

  preload() {
    this.load.image("background", "assets/backgrounds/cool-background (1).png");
  }

  create() {
    //this.socket = io();
    let scene = this;
    const canvasWidth = scene.scale.width;
    const canvasHeight = scene.scale.height;
    this.scoreSheet = new ScoreSheetFull(scene);

    const containerHeight = 120;
    // Invisible button to start a new game
    const leave = scene.add
      .dom(-10, -10)
      .createFromCache("leaveGameButton")
      .setVisible(false)
      .setInteractive();

    const placementColor = {
      1: 0xffd700,
      2: 0xc0c0c0,
      3: 0xcd7f32,
      4: 0xb87333,
    };

    const teamsPlacement = {
      0: "W",
      1: "L",
    };

    scene.add
      .text(canvasWidth / 2, 150, "Game Over")
      .setOrigin(0.5, 0.5)
      .setFontSize(64)
      .setColor("#ff0000");

    scene.startOver = this.add
      .text(canvasWidth / 2 + 150, canvasHeight - 280, "Restart")
      .setOrigin(0.5, 0.5)
      .setFontSize(32)
      .setColor("#FF0000")
      .setInteractive();

    let sortedPlayers = Object.entries(this.players).sort((a, b) => {
      return b[1].finalPoints - a[1].finalPoints;
    });

    // If teammed game, 2 vaues will be falsy and we don't want their values to display
    sortedPlayers = sortedPlayers.filter(function (player) {
      return !!player[1].finalPoints;
    });

    // Iterate through sorted players and display their scores
    for (let i = 0; i < sortedPlayers.length; i++) {
      //   console.log(sortedPlayers[i][1]);
      let cont = this.add.container(
        canvasWidth / 2,
        (canvasHeight + containerHeight) / 2 +
          (i - sortedPlayers.length / 2) * containerHeight
      );

      let placement = scene.add
        .text(-200, 0, i + 1)
        .setFontSize(70)
        .setOrigin(0.5);

      const scoreBox = scene.add
        .rectangle(0, 0, 500, 120, 0x2e2e2e)
        .setStrokeStyle(4, placementColor[i + 1]);

      // Put players display names and images in array
      let nameArr = [];
      let imgArr = [];

      const playerName = sortedPlayers[i][1].name;
      const playerImgUrl = sortedPlayers[i][1].profilePic;
      nameArr.push(playerName);
      imgArr.push(playerImgUrl);
      const teamMateId = sortedPlayers[i][1].teamMemberId;
      const teammateName = this.players[teamMateId]?.name;
      const teammateImgUrl = this.players[teamMateId]?.profilePic;
      nameArr.push(teammateName);
      imgArr.push(teammateImgUrl);

      console.log(teammateName);
      console.log(teammateImgUrl);

      let imgStyleArr = [];
      for (let j = 0; j < nameArr.length; j++) {
        let style = `background: url("${imgArr[j]}"); background-size: cover; width: 90px; height: 90px`;
        let img = scene.add
          .dom()
          .createElement("span", style)
          .setPosition(-100 + 100 * j, -10);
        imgStyleArr.push(img);

        let name = scene.add
          .text(-100 + 100 * j, 45, nameArr[j])
          .setFontSize(20)
          .setOrigin(0.5);
        nameArr[j] = name;
      }

      let points = scene.add
        .text(120, 0, (sortedPlayers[i][1].finalPoints / 100).toFixed(1))
        .setFontSize(70)
        .setOrigin(0.5);

      //   if (sortedPlayers.length === 2) {
      //     placement = teamsPlacement[i];
      //   }

      cont.add([scoreBox, placement, ...imgStyleArr, ...nameArr, points]);
    }

    scene.scoreSheet.updateTable();

    scene.startOver.on("pointerover", () => {
      scene.startOver.setColor("#ff69b4");
    });

    scene.startOver.on("pointerout", () => {
      scene.startOver.setColor("#FF0000");
    });

    scene.startOver.on("pointerdown", () => {
      console.log("on startOverPointerdown");
      window.location.href = "/room";
    });
  }
}
