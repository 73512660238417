import constants from "./constants";
import { LayoutManager } from "./LayoutManager";
import playerLocation from "./players";
import CardSprite from "./CardSprite";
import { getCard } from "./helpers";

export class JokerCall {
  constructor(scene) {
    this.scene = scene; // Store scene reference
    this.canvasWidth = scene.scale.width;
    this.canvasHeight = scene.scale.height;
    this.layoutManager = new LayoutManager(scene);
    this.card = new CardSprite(scene);
    // this.speechBubble = new SpeechBubble(scene);
    // this.jokerHighestCardsCallsContainer = this.scene.add.container();
    // this.jokerSuiteTakesCardsCallsContainer = this.scene.add.container();
    this.jokerCalls = this.scene.add.group();
    this.jokerOptions = this.scene.add.group();
    this.highestSuiteJokerDemandBG = this.scene.add
      .text(
        this.layoutManager.getLayout().jokerHighCardsCall.x,
        this.layoutManager.getLayout().jokerHighCardsCall.y - 40,
        "მაღალი",
        {
          fontFamily: "Arial",
          fontSize: "bold 32px",
          color: "#ffffff",
          align: "center",
          fixedWidth: 460,
          backgroundColor: "#db1414",
          padding: {
            // left: 80,
            // right: 80,
            top: 160,
            bottom: 5,
          },
        }
      )
      .setOrigin(0.5)
      .setVisible(false);

    this.suiteTakesJokerCallBG = this.scene.add
      .text(
        this.layoutManager.getLayout().jokerSuiteTakesCall.x,
        this.layoutManager.getLayout().jokerSuiteTakesCall.y + 40,
        "წაიღოს",
        {
          fontFamily: "Arial",
          fontSize: "bold 32px",
          color: "#ffffff",
          align: "center",
          fixedWidth: 460,
          backgroundColor: "#25afff",
          padding: {
            // left: 80,
            // right: 80,
            top: 5,
            bottom: 160,
          },
        }
      )
      .setOrigin(0.5)
      .setVisible(false);

    this.jokerOverBG = this.scene.add
      .text(
        this.layoutManager.getLayout().jokerOver.x + 320,
        this.layoutManager.getLayout().jokerOver.y + 120,
        "მოჯოკვრა",
        {
          fontFamily: "Arial",
          fontSize: "24px",
          color: "#ffffff",
          align: "center",
          fixedWidth: 120,
          backgroundColor: "#db1414",
          padding: {
            // left: 80,
            // right: 80,
            top: 150,
            bottom: 5,
          },
        }
      )
      .setOrigin(0.5)
      .setVisible(false);

    this.jokerUnderBG = this.scene.add
      .text(
        this.layoutManager.getLayout().jokerUnder.x + 320,
        this.layoutManager.getLayout().jokerUnder.y + 120,
        "გატანება",
        {
          fontFamily: "Arial",
          fontSize: "24px",
          color: "#ffffff",
          align: "center",
          fixedWidth: 120,
          backgroundColor: "#25afff",
          padding: {
            // left: 80,
            // right: 80,
            top: 150,
            bottom: 5,
          },
        }
      )
      .setOrigin(0.5)
      .setVisible(false);

    this.JokerAnnouncement = this.scene.add
      .text(this.canvasWidth / 2 - 130, 200, "", {
        backgroundColor: "#ffffff",
        color: constants.JOKER_CALL_BACKGROUND_COLOR,
        font: "bold 30px monospace",
        padding: 3,
        align: "center",
      })
      .setVisible(false)
      .setOrigin(0.5);
  }

  showJokerCalls() {
    this.highestSuiteJokerDemandBG.setVisible(true);
    this.suiteTakesJokerCallBG.setVisible(true);
    this.jokerHighestCardsCallsContainer = this.scene.add.container();
    this.jokerSuiteTakesCardsCallsContainer = this.scene.add.container();
    const y = 10;
    let highClubs = this.scene.add
      .image(-165, -y, "15_of_clubs")
      .setScale(0.4)
      .setInteractive();
    let highDiamonds = this.scene.add
      .image(-55, -y, "15_of_diamonds")
      .setScale(0.4)
      .setInteractive();
    let highHearts = this.scene.add
      .image(55, -y, "15_of_hearts")
      .setScale(0.4)
      .setInteractive();
    let highSpades = this.scene.add
      .image(165, -y, "15_of_spades")
      .setScale(0.4)
      .setInteractive();

    let takeClubs = this.scene.add
      .image(-165, y, "5_of_clubs")
      .setScale(0.4)
      .setInteractive();
    let takeDiamonds = this.scene.add
      .image(-55, y, "5_of_diamonds")
      .setScale(0.4)
      .setInteractive();
    let takeHearts = this.scene.add
      .image(55, y, "5_of_hearts")
      .setScale(0.4)
      .setInteractive();
    let takeSpades = this.scene.add
      .image(165, y, "5_of_spades")
      .setScale(0.4)
      .setInteractive();

    this.jokerHighestCardsCallsContainer.add([
      highClubs,
      highDiamonds,
      highHearts,
      highSpades,
    ]);

    this.jokerSuiteTakesCardsCallsContainer.add([
      takeClubs,
      takeDiamonds,
      takeHearts,
      takeSpades,
    ]);

    this.jokerHighestCardsCallsContainer.setPosition(
      this.layoutManager.getLayout().jokerHighCardsCall.x,
      this.layoutManager.getLayout().jokerHighCardsCall.y - 40
    );

    this.jokerSuiteTakesCardsCallsContainer.setPosition(
      this.layoutManager.getLayout().jokerSuiteTakesCall.x,
      this.layoutManager.getLayout().jokerSuiteTakesCall.y + 40
    );

    this.jokerCalls.add(highClubs);
    this.jokerCalls.add(highDiamonds);
    this.jokerCalls.add(highHearts);
    this.jokerCalls.add(highSpades);

    this.jokerCalls.add(takeClubs);
    this.jokerCalls.add(takeDiamonds);
    this.jokerCalls.add(takeHearts);
    this.jokerCalls.add(takeSpades);
  }

  // showJokerDemandsHigh() {
  //   const suites = [
  //     "15_of_clubs",
  //     "15_of_diamonds",
  //     "15_of_hearts",
  //     "15_of_spades",
  //   ];
  //   const suiteCards = this.card.createCardObjectsFromName(suites);
  //   suiteCards.forEach((card) => {
  //     card.setOrigin(0.5).setInteractive().setTint();
  //   });
  //   //this.trumpCallCards.addMultiple(suiteCards);
  //   this.card.renderPlayerHand(
  //     suiteCards,
  //     this.layoutManager.getLayout().playAreaCenter.y - 95
  //   );
  // }

  // showJokerCallsTake() {
  //   const suites = [
  //     "5_of_clubs",
  //     "5_of_diamonds",
  //     "5_of_hearts",
  //     "5_of_spades",
  //   ];
  //   const suiteCards = this.card.createCardObjectsFromName(suites);
  //   suiteCards.forEach((card) => {
  //     card.setOrigin(0.5).setInteractive().setTint();
  //   });
  //   //this.trumpCallCards.addMultiple(suiteCards);
  //   this.card.renderPlayerHand(
  //     suiteCards,
  //     this.layoutManager.getLayout().playAreaCenter.y + 95
  //   );
  // }

  // turnJokerCallsOn() {
  //   let xLoc = 270;
  //   let yLoc = 455;
  //   let width = 60;
  //   this.highestSuiteJokerDemandBG.setVisible(true);
  //   this.suiteTakesJokerCallBG.setVisible(true);
  //   let highClubs = this.scene.add
  //     .image(this.canvasWidth / 2 - xLoc, yLoc, "15_of_clubs")
  //     .setScale(0.25, 0.25)
  //     .setInteractive();
  //   let highDiamonds = this.scene.add
  //     .image(this.canvasWidth / 2 - (xLoc - width * 1), yLoc, "15_of_diamonds")
  //     .setScale(0.25, 0.25)
  //     .setInteractive();
  //   let highHearts = this.scene.add
  //     .image(this.canvasWidth / 2 - (xLoc - width * 2), yLoc, "15_of_hearts")
  //     .setScale(0.25, 0.25)
  //     .setInteractive();
  //   let highSpades = this.scene.add
  //     .image(this.canvasWidth / 2 - (xLoc - width * 3), yLoc, "15_of_spades")
  //     .setScale(0.25, 0.25)
  //     .setInteractive();

  //   let takeClubs = this.scene.add
  //     .image(this.canvasWidth / 2 + (xLoc - width * 3), yLoc, "5_of_clubs")
  //     .setScale(0.25, 0.25)
  //     .setInteractive();
  //   let takeDiamonds = this.scene.add
  //     .image(this.canvasWidth / 2 + (xLoc - width * 2), yLoc, "5_of_diamonds")
  //     .setScale(0.25, 0.25)
  //     .setInteractive();
  //   let takeHearts = this.scene.add
  //     .image(this.canvasWidth / 2 + (xLoc - width * 1), yLoc, "5_of_hearts")
  //     .setScale(0.25, 0.25)
  //     .setInteractive();
  //   let takeSpades = this.scene.add
  //     .image(this.canvasWidth / 2 + xLoc, yLoc, "5_of_spades")
  //     .setScale(0.25, 0.25)
  //     .setInteractive();

  //   this.jokerCalls.add(highClubs);
  //   this.jokerCalls.add(highDiamonds);
  //   this.jokerCalls.add(highHearts);
  //   this.jokerCalls.add(highSpades);

  //   this.jokerCalls.add(takeClubs);
  //   this.jokerCalls.add(takeDiamonds);
  //   this.jokerCalls.add(takeHearts);
  //   this.jokerCalls.add(takeSpades);
  // }

  turnJokerOptionsOn() {
    let yLoc = this.layoutManager.getLayout().jokerOver.y + 115;

    this.jokerOverBG.setVisible(true);
    this.jokerUnderBG.setVisible(true);
    let joker_red = this.scene.add
      .image(
        this.layoutManager.getLayout().jokerOver.x + 320,
        yLoc,
        "15_of_joker"
      )
      .setScale(0.4)
      .setInteractive();
    let joker_black = this.scene.add
      .image(
        this.layoutManager.getLayout().jokerUnder.x + 320,
        yLoc,
        "5_of_joker"
      )
      .setScale(0.4)
      .setInteractive();

    this.jokerOptions.add(joker_red);
    this.jokerOptions.add(joker_black);
  }

  removeJokerCalls() {
    //if (this.jokerCalls.getChildren().some((child) => child.visible)) {
    this.jokerCalls.clear(true, true);
    this.highestSuiteJokerDemandBG.setVisible(false);
    this.suiteTakesJokerCallBG.setVisible(false);
    console.log("Joker calls removed");
    //}
  }

  removeJokerOptions() {
    //if (this.jokerOptions.getChildren().some((child) => child.visible)) {
    this.jokerOptions.clear(true, true);
    this.jokerOverBG.setVisible(false);
    this.jokerUnderBG.setVisible(false);
    console.log("Joker options removed");
    //}
  }

  jokerRequest(item, playerId) {
    console.log("JokerDemand card", item);
    const { suit, rank } = item;

    // const position = playerLocation[playerId].position;
    const demand = rank == "15" ? "მაღალი" : "წაიღოს";
    const message = `${demand} ${constants[suit]}`;

    // Show the speech bubble with the arrow pointing to the player
    // const bubble = this.scene.speechBubble.speechBubbleArray.find(
    //   (p) => p.name == position
    // );
    // bubble.setVisible(true);

    // Show joker demand on the bubble
    // this.scene.speechBubble.showMessage(message);

    const { xLoc, yLoc } = this.scene.getPlayedCardLocation(playerId);
    this.scene.jokerCallBanner.setText(message);
    this.scene.jokerCallBanner.setPosition(
      xLoc - this.card.cardDisplayWidth / 2,
      yLoc -
        (this.card.cardDisplayWidth * constants.CARD_HEIGHT_TO_WIDTH_RATIO) / 2
    );
    this.scene.jokerCallBanner.setVisible(true);

    // this.JokerAnnouncement.setText(
    //   value + "\n" + constants[item.suit]
    // ).setVisible(true);
    // this.jokerCall = self.card
    //   .renderPlayingCard(680, 150, item.textureKey)
    //   .setScale(0.25);
  }
}
