class Card {
  constructor(suit, rank, weight, textureKey) {
    this.suit = suit;
    this.rank = rank;
    this.weight = weight;
    this.textureKey = textureKey;
  }

  static fromObject(obj) {
    return new Card(obj.suit, obj.rank, obj.weight, obj.textureKey);
  }

  toObject() {
    return {
      suit: this.suit,
      rank: this.rank,
      weight: this.weight,
      textureKey: this.textureKey,
    };
  }
}

module.exports = Card;
