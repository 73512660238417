// mainMenu.js
export default class MainMenu extends Phaser.Scene {
  constructor() {
    super("MainMenu");
  }

  preload() {
    let deck = "bridge/";
    let ext = ".svg";

    this.playerId = sessionStorage.getItem("userId");
    this.roomId = sessionStorage.getItem("roomId");

    // Store data directly on the scene object
    this.playerData = {
      playerId: this.playerId,
      roomId: this.roomId,
    };

    this.load.atlas(
      "cards",
      "assets/playing_cards_ru.jpeg",
      "assets/data/playing_cards_ru.json"
    );

    // TODO when the next player joins the game, previous players do not know about his usrId (joining usr's image URL is not in existing players preload() method)
    // because the lines below won't render for previous players.
    // Need to collect users info in the waiting room and then render them here all at once.
    // In the waiting room we can render images using DOM
    // for (const [id, url] of Object.entries(this.photos)) {
    //   console.log(id, url);
    //   this.load.image(id, url);
    // }

    this.load.image(
      "6_of_diamonds",
      "assets/deck/" + deck + "6_of_diamonds" + ext
    );
    this.load.image("6_of_hearts", "assets/deck/" + deck + "6_of_hearts" + ext);
    this.load.image("7_of_clubs", "assets/deck/" + deck + "7_of_clubs" + ext);
    this.load.image(
      "7_of_diamonds",
      "assets/deck/" + deck + "7_of_diamonds" + ext
    );
    this.load.image("7_of_hearts", "assets/deck/" + deck + "7_of_hearts" + ext);
    this.load.image("7_of_spades", "assets/deck/" + deck + "7_of_spades" + ext);
    this.load.image("8_of_clubs", "assets/deck/" + deck + "8_of_clubs" + ext);
    this.load.image(
      "8_of_diamonds",
      "assets/deck/" + deck + "8_of_diamonds" + ext
    );
    this.load.image("8_of_hearts", "assets/deck/" + deck + "8_of_hearts" + ext);
    this.load.image("8_of_spades", "assets/deck/" + deck + "8_of_spades" + ext);
    this.load.image("9_of_clubs", "assets/deck/" + deck + "9_of_clubs" + ext);
    this.load.image(
      "9_of_diamonds",
      "assets/deck/" + deck + "9_of_diamonds" + ext
    );
    this.load.image("9_of_hearts", "assets/deck/" + deck + "9_of_hearts" + ext);
    this.load.image("9_of_spades", "assets/deck/" + deck + "9_of_spades" + ext);
    this.load.image("10_of_clubs", "assets/deck/" + deck + "10_of_clubs" + ext);
    this.load.image(
      "10_of_diamonds",
      "assets/deck/" + deck + "10_of_diamonds" + ext
    );
    this.load.image(
      "10_of_hearts",
      "assets/deck/" + deck + "10_of_hearts" + ext
    );
    this.load.image(
      "10_of_spades",
      "assets/deck/" + deck + "10_of_spades" + ext
    );
    this.load.image(
      "ace_of_clubs",
      "assets/deck/" + deck + "ace_of_clubs" + ext
    );
    this.load.image(
      "ace_of_diamonds",
      "assets/deck/" + deck + "ace_of_diamonds" + ext
    );
    this.load.image(
      "ace_of_hearts",
      "assets/deck/" + deck + "ace_of_hearts" + ext
    );
    this.load.image(
      "ace_of_spades",
      "assets/deck/" + deck + "ace_of_spades" + ext
    );
    this.load.image(
      "black_of_joker",
      "assets/deck/" + deck + "black_joker" + ext
    );
    this.load.image(
      "jack_of_clubs",
      "assets/deck/" + deck + "jack_of_clubs" + ext
    );
    this.load.image(
      "jack_of_diamonds",
      "assets/deck/" + deck + "jack_of_diamonds" + ext
    );
    this.load.image(
      "jack_of_hearts",
      "assets/deck/" + deck + "jack_of_hearts" + ext
    );
    this.load.image(
      "jack_of_spades",
      "assets/deck/" + deck + "jack_of_spades" + ext
    );
    this.load.image(
      "king_of_clubs",
      "assets/deck/" + deck + "king_of_clubs" + ext
    );
    this.load.image(
      "king_of_diamonds",
      "assets/deck/" + deck + "king_of_diamonds" + ext
    );
    this.load.image(
      "king_of_hearts",
      "assets/deck/" + deck + "king_of_hearts" + ext
    );
    this.load.image(
      "king_of_spades",
      "assets/deck/" + deck + "king_of_spades" + ext
    );
    this.load.image(
      "queen_of_clubs",
      "assets/deck/" + deck + "queen_of_clubs" + ext
    );
    this.load.image(
      "queen_of_diamonds",
      "assets/deck/" + deck + "queen_of_diamonds" + ext
    );
    this.load.image(
      "queen_of_hearts",
      "assets/deck/" + deck + "queen_of_hearts" + ext
    );
    this.load.image(
      "queen_of_spades",
      "assets/deck/" + deck + "queen_of_spades" + ext
    );
    this.load.image("red_of_joker", "assets/deck/" + deck + "red_joker" + ext);
    this.load.image("card_back", "assets/deck/" + deck + "card_back" + ext);

    // Trumps
    this.load.image(
      "15_of_clubs",
      "assets/deck/" + deck + "trumps/clubs" + ext
    );
    this.load.image(
      "15_of_diamonds",
      "assets/deck/" + deck + "trumps/diamonds" + ext
    );
    this.load.image(
      "15_of_hearts",
      "assets/deck/" + deck + "trumps/hearts" + ext
    );
    this.load.image(
      "15_of_spades",
      "assets/deck/" + deck + "trumps/spades" + ext
    );

    this.load.image("5_of_clubs", "assets/deck/" + deck + "trumps/clubs" + ext);
    this.load.image(
      "5_of_diamonds",
      "assets/deck/" + deck + "trumps/diamonds" + ext
    );
    this.load.image(
      "5_of_hearts",
      "assets/deck/" + deck + "trumps/hearts" + ext
    );
    this.load.image(
      "5_of_spades",
      "assets/deck/" + deck + "trumps/spades" + ext
    );

    // Jokers
    this.load.image("15_of_joker", "assets/deck/" + deck + "red_joker" + ext);
    this.load.image("5_of_joker", "assets/deck/" + deck + "black_joker" + ext);

    // Audios
    this.load.audio("card_played", [
      "assets/sounds/carddrop.mp3",
      "assets/sounds/carddrop.ogg",
    ]);
    this.load.audio("boo", ["assets/sounds/boo.mp3", ""]);
    this.load.audio("sarcasm", ["assets/sounds/sarcasticLaugh1.mp3", ""]);
    this.load.audio("like", ["assets/sounds/nice.mp3", ""]);
    this.load.audio("move", ["assets/sounds/move.mp3", ""]);
    // this.load.audio("angry", ["assets/sounds/noGodPleaseNo.mp3", ""]);
    this.load.audio("hissing", ["assets/sounds/hissing1.mp3", ""]);
    // this.load.audio('player_joined', 'assets/sounds/player-joined.mp3');
    // this.load.audio('cards_shuffled', 'assets/sounds/cards-shuffled.mp3');
    // this.load.audio('premia', 'assets/sounds/premia.mp3');
    // this.load.audio('slow_clap', 'assets/sounds/slow_clap.mp3');
    // this.load.audio('smack_the_head', 'assets/sounds/smack_the_head.mp3');
    // this.load.audio('no_god_no', 'assets/sounds/no_god_no.mp3');
    // from The Office

    this.load.image("background", "assets/backgrounds/blue-diamond.png");
  }

  create() {
    this.scene.start("GameScene", this.playerData); // Start the main menu after loading
    // // After loading, start the next scene (GameScene or SoloPracticeScene)
    // this.add.image(400, 300, "background");
    // let playButton = this.add.sprite(400, 300, "playButton").setInteractive();
    // playButton.on("pointerdown", () => {
    //   this.scene.start("GameScene");
    // });
    // let soloButton = this.add.sprite(400, 400, "soloButton").setInteractive();
    // soloButton.on("pointerdown", () => {
    //   this.scene.start("SoloPracticeScene");
    // });
  }
}
