import GameHandler from "./GameHandler.js";
import { JokerCall } from "./JokerCall.js";
import playerLocation from "./players.js";
import CardSprite from "./CardSprite.js";
import { LayoutManager } from "./LayoutManager.js";
import gameState from "./gameState.js";
import { getCard } from "./helpers.js";

export default class InteractivityHandler {
  constructor(scene) {
    this.scene = scene;
    this.gameHandler = new GameHandler(scene);
    this.jokerCall = new JokerCall(scene);
    this.card = new CardSprite(scene);
    this.layoutManager = new LayoutManager(scene);
    this.lastWonTrickSprites = this.scene.add.group();
    this.isLastTrickPlaceholderClicked = false;
    let isJokerClicked = false;

    scene.lastTrickPlaceholder.on(
      "pointerdown",
      (pointer, localX, localY, event) => {
        // Prevent the global input listener from triggering
        event.stopPropagation();
        this.toggleLastWonTrickSprites();
      }
    );

    scene.input.on("pointerdown", () => {
      console.log("input on pointerdown");
      this.clearPopupSprites();
    });

    this.clearPopupSprites = () => {
      if (this.isLastTrickPlaceholderClicked) {
        console.log("isLastTrickPlaceholderClicked is clicked");

        this.lastWonTrickSprites.clear(true, true);
        this.isLastTrickPlaceholderClicked = false;
      }

      if (scene.isTauntClicked) {
        console.log("Taunt is clicked");
        scene.removeTaunts();
        scene.isTauntClicked = false;
      }

      if (!this.scene.input._isGameObjectClicked) {
        this.jokerCall.removeJokerCalls();
        this.jokerCall.removeJokerOptions();
        // this.gameHandler.setLegalCards();
        isJokerClicked = false;
      }

      // Reset the custom flag
      this.scene.input._isGameObjectClicked = false;
    };

    scene.input.on("gameobjectdown", (pointer, gameObject) => {
      console.log("on gameObjectdown");
      this.onGameObjectDown(pointer, gameObject);
    });

    this.onGameObjectDown = (pointer, gameObject) => {
      console.log(`Gameobject clicked`, gameObject);
      if (this.isPlayingCard(gameObject)) {
        const cardData = gameObject.cardData;
        console.log(`GameObject is ${cardData.textureKey}`);
        // TODO remove permanently - doesn't work well with onPointerDown() method
        //this.disableAllCards(gameObject);
        if (cardData.textureKey.includes("joker")) {
          // Set a custom flag to prevent global pointerdown
          this.scene.input._isGameObjectClicked = true;
          console.log(`The gameobject is a Joker`);
          isJokerClicked = !isJokerClicked;
          if (isJokerClicked) {
            if (gameState.public.cardsPlayed.length === 0) {
              console.log(`Playing card is Joker and starts trick`);
              console.log(`Player clicked Joker. Showing joker call options`);
              this.jokerCall.showJokerCalls();
              Phaser.Actions.Call(
                this.jokerCall.jokerCalls.getChildren(),
                (item) => {
                  item.on("pointerdown", () => {
                    const { suit, rank } = getCard(item.texture.key);
                    cardData.suit = suit;
                    cardData.rank = rank;
                    cardData.weight = Number(rank);
                    console.log(
                      `Assigned suit, rank, and weight to joker card:`,
                      cardData
                    );
                    this.jokerCall.removeJokerCalls();
                    console.log(`Removed joker calls`);
                    this.playCard(gameObject, item);
                  });
                }
              );
            } else {
              console.log("Joker played but not starting trick");
              const firstCardData = gameState.public.cardsPlayed[0].card;
              console.log(firstCardData);
              this.jokerCall.turnJokerOptionsOn();
              Phaser.Actions.Call(
                this.jokerCall.jokerOptions.getChildren(),
                (item) => {
                  item.on("pointerdown", () => {
                    const { suit, rank } = getCard(item.texture.key);
                    if (rank == 15) {
                      cardData.suit = gameState.public.trump.suit;
                    } else {
                      cardData.suit = firstCardData.suit;
                    }
                    cardData.rank = rank;
                    cardData.weight = Number(rank);
                    console.log("Joker clicked:", cardData);
                    console.log("Joker clicked:", gameObject);

                    // Now `this` refers to the correct context (class or scene instance)
                    this.jokerCall.removeJokerOptions();
                    this.playCard(gameObject);
                  });
                }
              );
            }
          } else {
            this.jokerCall.removeJokerCalls();
            this.jokerCall.removeJokerOptions();
            // TODO remove permanently - doesn't work well with onPointerDown() method
            //this.gameHandler.setLegalCards();
          }
        } else if (!gameObject.texture.key.includes("5_of")) {
          console.log("It's a game object and at the end");
          this.playCard(gameObject);
        }
      }
    };
  }

  toggleLastWonTrickSprites() {
    if (this.isLastTrickPlaceholderClicked) {
      console.log(`Hiding last won tricks`);
      this.lastWonTrickSprites.clear(true, true);
    } else {
      console.log(`Showing last won tricks`);
      gameState.public.lastWonTrick.forEach(({ playerId, card }) => {
        const position = playerLocation[playerId].position;
        let x = this.layoutManager.getLayout()[position].x + 80;
        let y = this.layoutManager.getLayout()[position].y;

        let cd = this.card
          .renderPlayingCard(x, y, card)
          .disableInteractive()
          .setVisible(true)
          .setOrigin(0.5)
          .setAlpha(0.7);
        this.lastWonTrickSprites.add(cd);
      });
    }
    this.isLastTrickPlaceholderClicked = !this.isLastTrickPlaceholderClicked;
  }

  playCard(card, jokerDemand) {
    // card.setOrigin(0.5);
    // this.scene.tweens.add({
    //   targets: card,
    //   x: {
    //     value: this.layoutManager.getLayout().playAreaCenter.x,
    //     duration: 100,
    //   },
    //   y: {
    //     value:
    //       this.layoutManager.getLayout().playAreaCenter.y +
    //       (card.displayHeight + this.card.padding) / 2,
    //     duration: 100,
    //   },
    // });

    // if (card.cardData.rank == 5) {
    //   // card.depth = -1;
    //   card.setAlpha(0.3);
    // } else {
    //   this.scene.children.bringToTop(card);
    // }

    // Disabling the card before emitting "cardPlayed" is very important for there not to happen 2 clicks on gameObject
    card.disableInteractive();

    this.scene.socket.emit("cardPlayed", {
      playerId: this.scene.playerId,
      roomId: this.scene.roomId,
      gameObject: card.cardData,
      jokerDemand,
    });

    // let removed = Phaser.Utils.Array.Remove(this.scene.playerHandObjects, card);
    // this.card.renderPlayerHand(this.scene.playerHandObjects);
  }

  isPlayingCard(card) {
    if (card.texture !== undefined) {
      for (let i = 0; i < this.scene.playingCards.getChildren().length; i++) {
        if (
          this.scene.playingCards.getChildren()[i].texture.key ===
          card.texture.key
        ) {
          return true;
        }
      }
    }
    return false;
  }

  disableAllCards(gameObject) {
    for (let i in this.scene.playerHandObjects) {
      if (this.scene.playerHandObjects[i] != gameObject) {
        this.scene.playerHandObjects[i].disableInteractive().setTint(0xbcbcb6);
      }
    }
  }

  isAnyMemberVisible(group) {
    const children = group.getChildren();
    return children.some((child) => child.visible);
  }
}
