import gameState from "./gameState";
import playerLocation from "./players";
import { LayoutManager } from "./LayoutManager";
import constants from "./constants";
import UIHandler from "./UIHandler";

export class Timer {
  constructor(scene, uiHandler) {
    this.scene = scene;
    this.layoutManager = new LayoutManager(scene);
    this.uiHandler = uiHandler;
    // this.timerStartRad = -1.57;
    this.timeLeft = 0; // Timer start value
  }

  // setCircleTimerValue(x, y, timer, endRad) {
  //   let _endRad = this.timerStartRad + 6.28 * endRad;
  //   timer.clear();
  //   timer.lineStyle(10, 0xff6600, 1);
  //   timer.beginPath();
  //   timer.arc(
  //     x,
  //     y,
  //     constants.DESKTOP_IMAGE_HEIGHT / 2 + 5,
  //     this.timerStartRad,
  //     _endRad,
  //     false
  //   );
  //   timer.strokePath().setDepth(2);
  // }

  makeRectangularTimer(x, y) {
    const barWidth = 100; //constants.location.imageSize.width;
    const xLoc = x - barWidth / 2;
    const yLoc = y; // + constants.location.imageSize.hight / 2;
    // draw frame around time bar
    let cont = this.scene.add.container(xLoc, yLoc);
    let timerFrame = this.scene.add.graphics();
    timerFrame.x = 0;
    timerFrame.y = 0;
    timerFrame.lineStyle(1, 0xff0000);
    timerFrame.strokeRect(0, 0, barWidth, 5);
    // timerFrame.angle = 90;
    //draw the bar
    let bar = this.scene.add.graphics();
    // bar.setAngle(90);
    //color the bar
    bar.fillStyle(0xff0000, 1);

    //fill the bar with a rectangle
    bar.fillRect(0, 0, barWidth, 5);

    //position the bar
    bar.x = 0;
    bar.y = 0;
    cont.add([bar, timerFrame]);

    return cont;
  }

  setRectangularTimerValue(bar, percentage) {
    //scale the bar
    if (bar) bar.scaleX = percentage / 100;
  }

  destroyTimeBar() {
    console.log("In destroyTimeBar");
    this.gameTimer?.remove(false);
    // scene.time.removeEvent(scene.gameTimer);

    this.timeBar?.destroy();
    // scene.circleTimer?.clear;
    // this.timer?.destroy();
    this.uiHandler.clearTimers();
  }

  //startTimer(playerInTurnId) {
  // console.log("In startTimer");
  // const timeAllowedToThink = 20;
  // const position = playerLocation[playerInTurnId].position;
  // let x = this.layoutManager.getLayout()[position].x;
  // let y = this.layoutManager.getLayout()[position].y;
  // this.gameTimer?.remove(false);
  // // how long a player has to make a move
  // // we need a fresh timebar each time it is created
  // this.destroyTimeBar();
  // //this.timer = this.scene.add.graphics();
  // // scene.circleTimer = createCircleTimer(x, y);
  // this.timer?.clear();
  // // create a container with a timebar and frame around it at a specified location during a runtime
  // //this.timeBar = this.makeRectangularTimer(x, y);
  // // timeLeft variable that will get lower as time passes and will control the length of the timeBar
  // //let timeLeft = timeAllowedToThink;
  // // reference point. We need currentMillis because without it the event loop won't start unless player is on the page.
  // // however, can't have this because new Date.now() will start once the page loads/reloads and it will be different for every client
  // this.scene.socket.emit("timer", this.scene.roomId, (response) => {
  //   const timerMillis = response.startingMillis;
  //   let timeLeft;
  //   if (scene.isLandscape) {
  //     this.timer = this.scene.add.graphics();
  //   } else {
  //     this.timeBar = this.makeRectangularTimer(x, y);
  //     if (position === "left") {
  //       this.timeBar.angle = 90;
  //       this.timeBar.x = x + 20;
  //       this.timeBar.y = y - 50;
  //     } else if (position === "right") {
  //       this.timeBar.angle = 90;
  //       this.timeBar.x = x - 15;
  //       this.timeBar.y = y - 50;
  //     } else {
  //       this.timeBar.y = y + 12;
  //     }
  //   }
  //   this.gameTimer?.remove(false);
  //   // event loop
  //   this.gameTimer = this.scene.time.addEvent({
  //     // startAt: 1000,
  //     //delay: 1000,
  //     callback: () => {
  //       timeLeft =
  //         timeAllowedToThink - Math.floor((Date.now() - timerMillis) / 1000);
  //       if (scene.isLandscape) {
  //         this.setCircleTimerValue(
  //           x,
  //           y,
  //           this.timer,
  //           timeLeft / timeAllowedToThink
  //         );
  //       } else {
  //         this.setRectangularTimerValue(
  //           this.timeBar.list[0],
  //           timeLeft * (100 / timeAllowedToThink)
  //         );
  //       }
  //       //console.log(timeLeft);
  //       if (timeLeft < 0) {
  //         this.destroyTimeBar();
  //       }
  //     },
  //     callbackScope: this,
  //     loop: true,
  //     // repeat: 15,
  //   });
  // });
  //}

  startTimer(startingMillis) {
    console.log("In startTimer");
    const timeAllowedToThink = constants.THINK_TIME;
    const position = playerLocation[gameState.public.playerInTurnId].position;
    let x = this.layoutManager.getLayout()[position].x;
    let y = this.layoutManager.getLayout()[position].y;
    this.destroyTimeBar();
    // this.timer?.clear();
    // this.uiHandler.clearTimers();
    let timeLeft;
    if (scene.isLandscape) {
      this.timer = this.scene.add.graphics();
    } else {
      this.timeBar = this.makeRectangularTimer(x, y);
      if (position === "left") {
        this.timeBar.angle = 90;
        this.timeBar.x = x + 20;
        this.timeBar.y = y - 50;
      } else if (position === "right") {
        this.timeBar.angle = 90;
        this.timeBar.x = x - 15;
        this.timeBar.y = y - 50;
      } else {
        this.timeBar.y = y + 12;
      }
    }
    // this.gameTimer?.remove(false);
    // Immediate update before the timer loop starts
    const updateTimer = () => {
      timeLeft =
        timeAllowedToThink - Math.floor((Date.now() - startingMillis) / 1000);
      console.log(timeLeft);

      if (scene.isLandscape) {
        // this.setCircleTimerValue(
        //   x,
        //   y,
        //   this.timer,
        //   timeLeft / timeAllowedToThink
        // );

        const timer = this.uiHandler.findItemByName(
          playerLocation[gameState.public.playerInTurnId].container,
          "timer"
        );

        this.uiHandler.setCircleTimerValue(
          timer,
          timeLeft / timeAllowedToThink
        );
      } else {
        this.setRectangularTimerValue(
          this.timeBar.list[0],
          timeLeft * (100 / timeAllowedToThink)
        );
      }

      // Destroy the timer bar when time is up
      if (timeLeft < 0) {
        this.destroyTimeBar();
      }
    };

    // Call the update immediately to show the timer right away
    updateTimer();

    // Start the Phaser timer event to call `updateTimer` every second
    this.gameTimer = this.scene.time.addEvent({
      delay: 1000,
      callback: updateTimer,
      callbackScope: this,
      loop: true,
    });
  }

  startDesktopTimer(duration) {
    this.timeLeft = duration;
    this.timeEvent = this.scene.time.addEvent({
      delay: 1000, // Call the callback every 1 second
      callback: this.updateDesktopTimer, // Function to call on each tick
      callbackScope: this, // Ensure the correct 'this' context
      loop: true, // Repeat this event
    });
  }

  // Update desktop timer
  updateDesktopTimer() {
    this.timeLeft--;
    this.timerText.setText(`Time Left: ${this.timeLeft}s`);

    if (this.timeLeft <= 0) {
      this.timeEvent.remove(false); // Stop the timer event
      this.scene.socket.emit("timeUp"); // Notify the server that time is up
      this.timerText.setText("Time Up!");
    }
  }

  // updateTimer() {
  //   // Reduce time left by 1 second
  //   this.timeLeft--;

  //   // Update the text to show the time left
  //   //this.timerText.setText(`Time Left: ${this.timeLeft}s`);

  //   timeLeft =
  //     timeAllowedToThink - Math.floor((Date.now() - timerMillis) / 1000);
  //   if (scene.isLandscape) {
  //     this.setCircleTimerValue(x, y, this.timer, timeLeft / timeAllowedToThink);
  //   } else {
  //     this.setRectangularTimerValue(
  //       this.timeBar.list[0],
  //       timeLeft * (100 / timeAllowedToThink)
  //     );
  //   }

  //   // Check if time has run out
  //   if (this.timeLeft <= 0) {
  //     this.gameTimer.remove(false); // Stop the timer event
  //     //this.scene.socket.emit("timeUp"); // Notify the server that time is up
  //     //this.timerText.setText("Time Up!");
  //   }
  // }
}
