import playerLocation from "./players";
import gameState from "./gameState";

export default class GameHandler {
  constructor(scene) {
    this.setLegalCards = () => {
      console.log("Setting cards that players are allowed to play");

      // This array will hold all cards that are allowed for playing
      // this.legalCards = [];

      const bottomPlayerId = Object.keys(playerLocation).find(
        (key) => playerLocation[key].position === "bottom"
      );

      console.log(`Bottom player ID is: ${bottomPlayerId}`);
      console.log(`Player in turn ID is: ${gameState.public.playerInTurnId}`);

      if (bottomPlayerId === gameState.public.playerInTurnId) {
        console.log(
          `Bottom players turn to make a move. Setting up "legal cards"`
        );

        console.log(`Played cards:`, gameState.public.cardsPlayed);

        const playedCardsLength = gameState.public.cardsPlayed.length;
        let firstCardData = gameState.public.cardsPlayed[0]?.card;
        let firstSuit = firstCardData?.suit || "";
        let activeCards = [];

        // TODO instead use: const playerHand = gameState.private.cards;

        if (playedCardsLength === 0) {
          activeCards = [...scene.playerHandObjects];
        } else {
          const trump = gameState.public.trump.suit;

          // Find if player holds first played card suit.
          activeCards = this.getCardsBySuit(scene.playerHandObjects, firstSuit);

          // if player has the mathing suit and if the first played card was a demand by joker, we only need the highest card
          if (activeCards.length > 0 && firstCardData?.rank.includes("15")) {
            activeCards.splice(1);
          }

          // if player has no matching suit and first played card is not trump, find all trump cards.
          // This will include scenario when the first played card is suit takes demand from joker.
          if (activeCards.length === 0 && firstSuit != trump) {
            activeCards = this.getCardsBySuit(scene.playerHandObjects, trump);
          }

          // if player has no matching suit and no trump, setInteractive all cards
          if (activeCards.length === 0) {
            activeCards = [...scene.playerHandObjects];
          }

          // Joker is legal in any scenario
          let jokerArr = this.getJoker(scene.playerHandObjects);

          //activeCards.push(...jokerArr);
          activeCards.push(
            ...jokerArr.filter((item) => !activeCards.includes(item))
          );
        }
        console.log(activeCards);
        for (let card of activeCards) {
          card.setInteractive().setTint();
        }

        // let matchSuit = [];
        // let trumpSuit = [];
        // scene.playerHand.find((card) => {
        //   const suit = card.cardData.suit;
        //   if (suit.includes(firstSuit)) {
        //     console.log("found matched suit");
        //     matchSuit.push(card);
        //   }
        //   if (
        //     suit.includes(gameState.public.trump.suit) &&
        //     !suit.includes("joker")
        //   ) {
        //     console.log("found matched trump");
        //     trumpSuit.push(card);
        //   }
        // });

        // for (let i in scene.playerHand) {
        //   if (scene.playerHand[i].texture.key.includes("joker")) {
        //     this.legalCards.push(scene.playerHand[i]);
        //   }
        // }

        // if (matchSuit.length > 0) {
        //   if (playedCardsLength > 0 && firstCardData.rank.includes("15")) {
        //     this.legalCards.push(matchSuit[0]);
        //   } else {
        //     for (let i in matchSuit) {
        //       console.log("suit is more");
        //       this.legalCards.push(matchSuit[i]);
        //     }
        //   }
        // } else if (trumpSuit.length > 0) {
        //   for (let i in trumpSuit) {
        //     this.legalCards.push(trumpSuit[i]);
        //   }
        // } else {
        //   for (let i in scene.playerHand ||
        //     self.dropZone.data.values.cards === 0 ||
        //     !scene.playerHand[i].texture.key.includes("joker")) {
        //     this.legalCards.push(scene.playerHand[i]);
        //   }
        // }
        // for (let card of this.legalCards) {
        //   card.setInteractive().setTint();
        // }
      }
      //console.log("Cards allowed to play: ", this.legalCards);
    };
  }

  getCardsBySuit(cardsPlayed, suit) {
    return cardsPlayed.filter((item) => {
      const _suit = item.cardData.suit;
      if (_suit != "joker") return _suit === suit;
    });
  }

  getJoker(cardsPlayed) {
    return cardsPlayed.filter(
      (item) => item.cardData.suit.toLowerCase() === "joker"
    );
  }
}
