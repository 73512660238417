import gameState from "./gameState";
import playerLocation from "./players";
import UIHandler from "./UIHandler";

export class BidAndTricks {
  constructor(scene) {
    this.scene = scene;
    this.uiHandler = new UIHandler(scene);
    this.bidPlaceholders = [];
  }

  clearBidPlaceholders() {
    for (let playerId in this.bidPlaceholders) {
      this.bidPlaceholders[playerId].clear(true, true);
    }
  }

  updateBidPlaceholders(playerId, bidMade) {
    for (let i = 0; i < Number(bidMade); i++) {
      this.bidPlaceholders[playerId]
        .getChildren()
        [i].setStrokeStyle(2, 0xffffff);
    }
  }

  renderBidsAndTricksPlaceholders(numOfCards) {
    console.log("On renderBidsAndTricksPlaceholders");
    // clearBidPlaceholders();
    let placeholderSpace = 30;
    for (let id in playerLocation) {
      // Each player will have a group for bid and trick placeholders
      let bidPlaceholderGroup = this.scene.add.group();
      for (let i = 0; i < numOfCards; i++) {
        let cardsSpan = placeholderSpace * numOfCards;

        // create bid and won trick placeholders
        const position = playerLocation[id].position;
        let bidPlaceholderRect = this.scene.add.rectangle(
          // TODO this locations are no longer valid. Putting zeros until this method is needed
          // constants.location[position].profile.x -
          //   cardsSpan / 2 +
          //   placeholderSpace / 2 +
          //   i * placeholderSpace,
          // constants.location[position].profile.y + 70,
          0,
          0,
          26,
          34,
          0x000000,
          0.5
        );

        bidPlaceholderRect.setStrokeStyle(2, 0x000000);

        // add all placeholders for one player to a group
        bidPlaceholderGroup.add(bidPlaceholderRect);
      }

      // add a group of placeholders to a player with the id
      this.bidPlaceholders[id] = bidPlaceholderGroup;
    }
  }

  updateWonTricks() {
    console.log("Updating tricks won");
    if (gameState.public.lastWonTrick.length > 0) {
      const players = gameState.public.players;

      Object.keys(players).forEach((playerId) => {
        //let tricksColor = "#ffffff";
        const bidMade = players[playerId].bidsMadeInCurrentRound;
        const tricksWon = players[playerId].tricksWonInCurrentRound;

        const bidAndTrickBoxText = this.uiHandler.findItemByName(
          playerLocation[playerId].container,
          "bidAndTrickBoxText"
        );

        const bidNtrickArc = this.uiHandler.findItemByName(
          playerLocation[playerId].container,
          "bidNtrickArc"
        );

        const bidNtrickArcGreen = this.uiHandler.findItemByName(
          playerLocation[playerId].container,
          "bidNtrickArcGreen"
        );

        const bidNtrickArcRed = this.uiHandler.findItemByName(
          playerLocation[playerId].container,
          "bidNtrickArcRed"
        );

        //if (tricksWon != undefined) {
        console.log(bidMade, tricksWon);
        if (bidMade === tricksWon) {
          //tricksColor = "#0b6623";
          //bidAndTrickBoxText.setColor("#0b6623");
          bidNtrickArc.setVisible(false);
          bidNtrickArcGreen.setVisible(true);
        } else if (bidMade < tricksWon) {
          //tricksColor = "#ff0000";
          //bidAndTrickBoxText.setColor("#ff0000");
          bidNtrickArc.setVisible(false);
          bidNtrickArcRed.setVisible(true);
        }
        // bidAndTrickBoxText.setColor(tricksColor);
        bidAndTrickBoxText.setText(
          `${bidMade === 0 ? "-" : bidMade}/${
            tricksWon === 0 ? "-" : tricksWon
          }`
        );

        // if (gameState.public.inPulkaRound > 0) {
        //   const doingPremia =
        //     players[playerId].score.pulka[gameState.public.pulka - 1].premia;
        //   const premiaRing = this.uiHandler.findItemByName(
        //     playerLocation[playerId].container,
        //     "premia"
        //   );

        //   if (doingPremia) {
        //     console.log("Doing");
        //     //premiaBox.setFillStyle(0x0b6623, 1);// circle
        //     this.uiHandler.drawPremiaRing(premiaRing, 0x0b6623);
        //   } else {
        //     console.log("Not doing");
        //     //premiaBox.setFillStyle(0xff0000, 1); // circle
        //     this.uiHandler.drawPremiaRing(premiaRing, 0xff0000);
        //   }
        // }
      });
    }
  }

  updatePremiaRings() {
    console.log("Updating premia rings");
    console.log(gameState.public.inPulkaRound);
    if (gameState.public.inPulkaRound > 0) {
      const players = gameState.public.players;
      Object.keys(players).forEach((playerId) => {
        const doingPremia =
          players[playerId].score.pulka[gameState.public.pulka - 1].premia;
        const premiaRing = this.uiHandler.findItemByName(
          playerLocation[playerId].container,
          "premia"
        );

        if (doingPremia) {
          console.log("Doing");
          //premiaBox.setFillStyle(0x0b6623, 1);// circle
          this.uiHandler.drawPremiaRing(premiaRing, 0x0b6623);
        } else {
          console.log("Not doing");
          //premiaBox.setFillStyle(0xff0000, 1); // circle
          this.uiHandler.drawPremiaRing(premiaRing, 0xff0000);
        }
      });
    }
  }

  updateBidAmount(players) {
    Object.keys(players).forEach((playerId) => {
      let bidMade = players[playerId].bidsMadeInCurrentRound;
      console.log(`Updating bid made - ${bidMade} for player ${playerId}`);
      // scene.bidAndTricks.updateBidPlaceholders(playerId, bidMade);
      if (bidMade != undefined) {
        const bidAndTrickBoxText = this.uiHandler.findItemByName(
          playerLocation[playerId].container,
          "bidAndTrickBoxText"
        );
        //playerLocation[playerId].container.list[4].setText(bidMade);
        bidAndTrickBoxText.setText(bidMade === 0 ? "-" : bidMade);
      }
    });
  }

  clearBidAndTrickBoxes() {
    console.log("Clearing bid and trick boxes");
    for (let cont of this.scene.uiHandler.containerArray) {
      // const bidAndTrickBoxText = cont.list.find(
      //   (item) => item.name === "bidAndTrickBoxText"
      // );
      const bidAndTrickBoxText = this.uiHandler.findItemByName(
        cont,
        "bidAndTrickBoxText"
      );
      // cont.list[4].setText("");
      // cont.list[5].setText("");
      bidAndTrickBoxText.setText("");
      //bidAndTrickBoxText.setColor("#000000");
      const bidNtrickArc = this.uiHandler.findItemByName(cont, "bidNtrickArc");

      const bidNtrickArcGreen = this.uiHandler.findItemByName(
        cont,
        "bidNtrickArcGreen"
      );

      const bidNtrickArcRed = this.uiHandler.findItemByName(
        cont,
        "bidNtrickArcRed"
      );
      bidNtrickArc.setVisible(true);
      bidNtrickArcGreen.setVisible(false);
      bidNtrickArcRed.setVisible(false);
    }
  }

  clearPremiaPlaceholders() {
    console.log("Clearing premia boxes");
    for (let cont of this.scene.uiHandler.containerArray) {
      // const premiaRing = cont.list.find((item) => item.name === "premia");
      const premiaRing = this.uiHandler.findItemByName(cont, "premia");
      //premiaBox.setFillStyle(0x000000, 0); circle
      this.uiHandler.drawPremiaRing(premiaRing, 0xffffff);
    }
  }
}

// updateWonTricks(players) {
//   // this.scene.socket.emit(
//   //   "playersData",
//   //   this.scene.roomId,
//   //   (response) => {
//   //const players = response.players;

//   // let roundEnded = false;
//   // Object.keys(players).forEach((playerId) => {
//   //   if (
//   //     // maybe: add roundEnded variable to gameState
//   //     players[playerId].tricksWonInCurrentRound != undefined &&
//   //     players[playerId].tricksWonInCurrentRound != 0
//   //   ) {
//   //     roundEnded = true;
//   //   }
//   // });
//   //console.log(roundEnded);
//   if (true) {
//     Object.keys(players).forEach((playerId) => {
//       const bidMade = players[playerId].bidsMadeInCurrentRound;
//       const tricksWon = players[playerId].tricksWonInCurrentRound;
//       let trickWonColor = "#999999";

//       const bidAndTrickBoxText = playerLocation[playerId].container.list.find(
//         (item) => item.name === "bidAndTrickBoxText"
//       );

//       if (tricksWon != undefined) {
//         if (bidMade === tricksWon) {
//           trickWonColor = "#0b6623";
//         } else if (bidMade < tricksWon) {
//           trickWonColor = "#ff0000";
//         }
//         bidAndTrickBoxText.setColor(trickWonColor);
//         bidAndTrickBoxText.setText(
//           `${bidMade === 0 ? "-" : bidMade}/${tricksWon}`
//         );
//         // playerLocation[playerId].container.list[5].setColor(
//         //   trickWonColor
//         // );
//         // playerLocation[playerId].container.list[5].setText(tricksWon);

//         // Remove comments if you want bid placeholders to display
//         // for (let i = 0; i < Number(tricksWon); i++) {
//         //   let trickFillColor = 0x0b6623;
//         //   if (bidMade < i + 1) {
//         //     trickFillColor = 0xff0000;
//         //   }
//         //   this.bidPlaceholders[playerId]
//         //     .getChildren()
//         //     [i].setFillStyle(trickFillColor, 1);
//         // }
//       }
//     });
//   }
// }
//   );
// }
