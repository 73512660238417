const gameState = {
  public: {
    bidNotAllowed: null,
    bidsTillVinujdeni: null,
    lastWonTrick: [],
    cardsPlayed: [],
    currentTurnType: null,
    dealerId: null,
    gameRound: 0, //value must be 0. Change for testing
    inPulkaRound: 0,
    numberOfCardsDealt: null,
    placedBids: null,
    playerInTurnId: null,
    playerIsReady: 0,
    players: {},
    pulka: 0,
    roomId: null,
    roundStarterId: null,
    teams: false,
    totalBids: 0,
    trump: null,
  },
  private: {
    trumpCallCards: [],
    cards: {},
  },
};

export default gameState;
