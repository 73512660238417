import { LayoutManager } from "./LayoutManager";
import constants from "./constants";

export default class CardSprite {
  constructor(scene) {
    this.scene = scene;
    this.layoutManager = new LayoutManager(scene);
    this.canvasWidth = scene.scale.width;
    this.canvasHeight = scene.scale.height;
    // this.cardScale = constants.MOBILE_CARD_SCALE;
    this.cardScale = this.layoutManager.getLayout().cardScale;

    // Distance between cards will be 8px on the smalles screen width or on all devices
    this.padding = constants.MOBILE_PADDING; //this.screenWidth * 0.025 on mobile;

    // if (this.canvasWidth > 800) {
    //   this.cardScale = 0.065;
    // }

    // Smalles card width will be 44px
    this.cardDisplayWidth = this.canvasWidth * this.cardScale;
    console.log(this.cardDisplayWidth);
    this.renderPlayingCard = (x, y, cardData) => {
      let cardSprite = null;
      if (this.scene.isDesktop) {
        cardSprite = scene.add
          .image(x, y, cardData.textureKey)
          .setTint(0xbcbcb6)
          .setOrigin(0.5, 0);
      } else {
        cardSprite = scene.add
          .image(x, y, "cards", cardData.textureKey)
          .setTint(0xbcbcb6)
          .setOrigin(0.5, 0);
      }

      // Actual card images are in different sizes. Scale factor is calculated when the card width is 44px on minimum mobile window width
      this.scaleFactor = (this.canvasWidth * this.cardScale) / cardSprite.width;
      this.cardHeight = cardSprite.height * this.scaleFactor;
      cardSprite.setScale(this.scaleFactor);
      cardSprite.cardData = cardData;
      console.log(cardSprite);
      return cardSprite;
    };
  }

  createCardObjects(cards) {
    if (cards != undefined) {
      let cardArray = [];
      cards.forEach((cardData) => {
        // let cardValue = cardData.rank + "_of_" + cardData.suit;
        let renderedCard = this.renderPlayingCard(0, 0, cardData);
        cardArray.push(renderedCard);
      });
      return cardArray;
    }
  }

  createCardObjectsFromName(cardDataArray) {
    if (cardDataArray.length != 0) {
      let cardArray = [];
      cardDataArray.forEach((cardData) => {
        let renderedCard = this.renderPlayingCard(0, 0, cardData);
        cardArray.push(renderedCard);
      });
      return cardArray;
    }
  }

  renderPlayerHand(cardObjects, yLocation) {
    console.log("On playerHandCardObjects");
    console.log(cardObjects);

    // What is the max number of cards that can fit in one row
    let numberOfCardsInTopRow = Math.floor(
      this.canvasWidth / (this.cardDisplayWidth + this.padding)
    );

    // If more than 9 cards can fit in one row, then make it 9 cards
    numberOfCardsInTopRow = Math.min(numberOfCardsInTopRow, 9);
    console.log(numberOfCardsInTopRow);

    if (cardObjects != undefined) {
      // this.scene.playerHand = cardObjects;
      cardObjects.forEach((card, index) => {
        // Total lenth of all cards including this.padding in between and edges
        let cardsSpan =
          (this.cardDisplayWidth + this.padding) *
          Math.min(cardObjects.length + 1, numberOfCardsInTopRow + 1);
        if (index + 1 > numberOfCardsInTopRow) {
          cardsSpan =
            (this.cardDisplayWidth + this.padding) *
            (cardObjects.length - numberOfCardsInTopRow + 1);
        }

        let xLoc =
          (this.canvasWidth - cardsSpan) / 2 +
          ((index % numberOfCardsInTopRow) + 1) *
            (this.cardDisplayWidth + this.padding);

        // We don't know the height of cards until the card is created so we set initial y location to zero
        let yLoc = yLocation;
        if (!yLocation) {
          if (cardObjects.length > numberOfCardsInTopRow) {
            console.log(`Player has ${cardObjects.length} cards`);

            // If player holds more then numberOfCardsInTopRow in hand and the card that we are rendering is less than or equal to that
            if (index + 1 <= numberOfCardsInTopRow) {
              console.log(`CardNum is ${index + 1} and putting it on top row`);
              // displayHeight gives the height as it is displayed, while height gives card height before scaling, even if the property is called after scaling
              yLoc =
                this.canvasHeight - (this.padding * 2 + card.displayHeight * 2);
            } else {
              console.log(
                `CardNum is ${index + 1} and putting it on bottom row`
              );
              yLoc = this.canvasHeight - (this.padding + card.displayHeight);
            }
          } else {
            yLoc =
              this.canvasHeight -
              20 -
              (this.layoutManager.getLayout().padding + card.displayHeight);
            console.log(`Card display yLoc ${yLoc}`);
          }
        }
        card.setX(xLoc);
        card.setY(yLoc);
      });
    }
  }

  // renderPlayerHand(playerHand) {
  //   console.log("On renderPlayerHand");
  //   let cardNum = 0;
  //   console.log(playerHand);
  //   if (playerHand != undefined) {
  //     gameState.private.cards = playerHand;
  //     playerHand.every((card) => {
  //       ++cardNum;
  //       let cardValue = card.value + "_of_" + card.suit;
  //       let cardsSpan = scene.cardWidth * (playerHand.length + 1);
  //       let cd = this.renderPlayingCard(
  //         (constants.width - cardsSpan) / 2 + cardNum * scene.cardWidth,
  //         constants.location.playerHand.y,
  //         cardValue
  //       );
  //       scene.playerHand.push(cd);
  //       scene.playingCards.add(cd);
  //       return true;
  //     });
  //   }
  //   console.log(scene.playerHand);
  //   console.log(gameState.private.cards);
  // }

  /*
   * For the dimentions inside this method, assuming the smallest mobile screen width on portrait mode is 320px
   */
  // renderResponsivePlayerHand(playerHand) {
  //   console.log("On renderPlayerHand");
  //   console.log(playerHand);

  //   // Distance between cards will be 8px on the smalles screen width or on all devices
  //   const this.padding = 8; //this.screenWidth * 0.025;

  //   // What is the max number of cards that can fit in one row
  //   let numberOfCardsInTopRow = Math.floor(
  //     this.screenWidth / (this.cardWidth + this.padding)
  //   );

  //   // If more than 9 cards can fit in one row, then make it 9 cards
  //   numberOfCardsInTopRow = Math.min(numberOfCardsInTopRow, 9);
  //   console.log(numberOfCardsInTopRow);

  //   if (playerHand != undefined) {
  //     gameState.private.cards = playerHand;
  //     playerHand.forEach((card, index) => {
  //       let cardValue = card.value + "_of_" + card.suit;
  //       // Total lenth of all cards including this.padding in between and edges
  //       let cardsSpan =
  //         (this.cardWidth + this.padding) *
  //         Math.min(playerHand.length + 1, numberOfCardsInTopRow + 1);
  //       if (index + 1 > numberOfCardsInTopRow) {
  //         cardsSpan =
  //           (this.cardWidth + this.padding) *
  //           (playerHand.length - numberOfCardsInTopRow + 1);
  //       }

  //       // We don't know the height of cards until the card is created so we set initial y location to zero
  //       let yLoc = 0;

  //       let xLoc =
  //         (this.screenWidth - cardsSpan) / 2 +
  //         ((index % numberOfCardsInTopRow) + 1) * (this.cardWidth + this.padding);

  //       // Render card
  //       let renderedCard = this.renderPlayingCard(xLoc, yLoc, cardValue);

  //       if (playerHand.length > numberOfCardsInTopRow) {
  //         console.log(`Player has ${playerHand.length} cards`);

  //         // If player holds more then numberOfCardsInTopRow in hand and the card that we are rendering is less than or equal to that
  //         if (index + 1 <= numberOfCardsInTopRow) {
  //           yLoc = this.screenHeight - this.padding * 2 - this.cardHeight * 2;
  //         } else {
  //           console.log(`CardNum is ${index + 1} and putting it on bottom row`);
  //           yLoc = this.screenHeight - this.padding - this.cardHeight;
  //         }
  //       } else {
  //         yLoc = this.screenHeight - 1.5 * (this.padding + this.cardHeight);
  //       }

  //       renderedCard.setY(yLoc);
  //       scene.playerHand.push(renderedCard);
  //       scene.playingCards.add(renderedCard);
  //     });
  //   }
  // }
}
