export default class ProfileImage {
  constructor(scene) {
    this.addProfileImage = (
      xLoc,
      yLoc,
      imageWidth,
      imageHeight,
      photo,
      alpha
    ) => {
      let img = document.createElement("img");
      img.src = photo;
      img.width = imageWidth;
      img.height = imageHeight;
      img.setAttribute("referrerpolicy", "no-referrer");
      let photo_dom = scene.add
        .dom(xLoc, yLoc, img, "border-radius: 50%;")
        //.dom(xLoc, yLoc, img)
        .setAlpha(alpha);
      //for round image do: "border-radius: 50%;"
      return img;
    };
  }
}
