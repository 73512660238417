export default class SoloPracticeScene extends Phaser.Scene {
  constructor() {
    super({
      key: "SoloPracticeScene",
    });
  }

  init(data) {}

  preload() {
    this.load.image("test", "assets/backgrounds/testName.png");
  }

  create() {
    /**
     * Player should be able to guess what cards players hold in their last turn (9th)
     * Points can be earned by guessing 1, 2, or all players last Suit/s
     * Higher points will be earned by guessing Rank/s as well
     *
     * UI:
     * List of Ranks and Suits - 14 (5 Suits and 9 Ranks or 4 Suits and 10 Ranks)
     * Playing card sprites placeholders
     * Robot player images
     * Timer option that will set how much time to pause after a player moves. Will disable after player starts the challenge. Lesser time will result in higher score
     *
     * Logic:
     * Shuffle
     * pass 9 cards to each player
     * Make only self cards visible
     * Randomly start playing in 3 - 2 - 1 - GO
     * Pause after each played card by the time set by the player.
     * When each player has only left with 1 card, pause the play and ask player to guess what cards each player has (excluding self)
     */
  }
}
