import config from "./config.js";

export function startGame() {
  console.log("Initializing Phaser Game...");

  // Hide header
  document.querySelector(".header").classList.add("hidden");

  // Hide room section, show game container
  document.getElementById("roomSection").style.display = "none";
  document.getElementById("game").classList.add("active");

  // Add game-active class to body
  document.body.classList.add("game-active");

  // Clear any existing game instance
  const gameContainer = document.getElementById("game");
  gameContainer.innerHTML = "";

  // Create a new Phaser game instance
  window.game = new Phaser.Game(config);
}
